import { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import * as React from 'react';
import { useEffect } from 'react';
import { findAllAction } from "../../plan.slice";
import SelectInput from 'app/components/SelectInput';
import { PaginationDto } from 'app/types/pagination.dto';
import { planFeature } from '../../plan.feature';

type Props = {
    required?: boolean
    name: string
    value: string
    error?: string
    disabled?: boolean
    onChange?: (event: SelectChangeEvent) => void
}

type Option = {
    label: string
    value: string
}

export default function PlanSelectInput(props: Props) {
    const dispatch = useAppDispatch();
    const list = useAppSelector((state) => state.plan.list);
    const [options, setOptions] = React.useState<Option[]>([])

    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        if (list.done && !Boolean(list.error)) {
            setOptions(list.data.map(row => {
                return {
                    label: row.name,
                    value: row.id!
                }
            }))
        } else {
            setOptions([])
        }
    }, [list])

    const refreshData = () => {
        let findAllOptions: PaginationDto = {
            page: 0,
            perPage: 0
        }

        dispatch(
            findAllAction(findAllOptions)
        )
    }

    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={planFeature.label.singular}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={props.onChange}
        />
    )
}