import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";
import { BaseUiEntity } from "app/classes/base-ui-entity";
import { JoinAndCountDefinition } from "app/types/join-and-count.type";

export type EntitySchema = {
  id?: string;
  name: string;
  key: string;
  createdAt: string;
  updatedAt: string;
};

export const entityInitialState = {
  id: undefined,
  name: "",
  key: "",
  createdAt: "",
  updatedAt: "",
};

const slug: string = "statement";

const name: EntityName = {
  singular: "Permiso",
  plural: "Permisos",
};

const primaryKeyAttribute: string = "id";

const linkedToViewAttribute: string = "name";

const attributes: EntityAttribute[] = [
  {
    key: "id",
    label: "ID",
    dataType: "string",
    showInTable: false
  },
  {
    key: "name",
    label: "Nombre",
    dataType: "string",
    showInTable: true
  },
  {
    key: "key",
    label: "Llave",
    dataType: "string",
    showInTable: true
  },
  {
    key: "createdAt",
    label: "Creado",
    dataType: "date",
    showInTable: true
  },
  {
    key: "updatedAt",
    label: "Actualizado",
    dataType: "date",
    showInTable: false
  },
];

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
  constructor() {
    super(
      name,
      slug,
      primaryKeyAttribute,
      linkedToViewAttribute,
      attributes,
      join,
      joinAndCount,
    )
  }
}
