import Joi from "joi";
import { CreateFromPlanDto } from "./create-from-plan.dto";
import { planFeature } from "app/modules/plan/plan.feature";
import { organizationFeature } from "app/modules/organization/organization.feature";

export const schema = Joi.object<CreateFromPlanDto, true>({
    planId: Joi
        .string()
        .label(planFeature.label.singular)
        .uuid()
        .required(),
    organizationId: Joi
        .string()
        .label(organizationFeature.label.singular)
        .uuid()
        .required(),
})
