import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName,
  UiEntity,
} from "../../interfaces/EntityInterface";
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { BaseUiEntity } from "app/classes/base-ui-entity";

export type EntitySchema = {
  id?: string;
  name: string;
  description: string;
  class: string
  createdAt: string;
  updatedAt: string;
};

export const entityInitialState = {
  id: undefined,
  name: "",
  description: "",
  class: "",
  createdAt: "",
  updatedAt: "",
};

const singularName = "Tipo de pregunta"
const pluralName = "Tipos de pregunta"

const slug: string = "question-type";

const name: EntityName = {
  singular: singularName,
  plural: pluralName,
};

const primaryKeyAttribute: string = "id";

const linkedToViewAttribute: string = "name";

const attributes: EntityAttribute[] = [
  {
    key: "id",
    label: "ID",
    dataType: "string",
    showInTable: false
  },
  {
    key: "name",
    label: "Nombre",
    dataType: "string",
    showInTable: true
  },
  {
    key: "description",
    label: "Descripción",
    dataType: "string",
    showInTable: true
  },
  {
    key: "createdAt",
    label: "Creado",
    dataType: "date",
    showInTable: true
  },
  {
    key: "updatedAt",
    label: "Actualizado",
    dataType: "date",
    showInTable: false
  },
];

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
  constructor() {
    super(
      name,
      slug,
      primaryKeyAttribute,
      linkedToViewAttribute,
      attributes,
      join,
      joinAndCount,
    )
  }
}
