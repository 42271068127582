import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName,
} from "../../interfaces/EntityInterface";
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { EntitySchema as QuestionTypeEntitySchema } from '../question-type/question-type.entity'
import { questionTypeFeature } from "../question-type/question-type.feature";
import { testDimensionFeature } from "../test-dimension/test-dimension.feature";
import { testVersionFeature } from "../test-version/test-version.feature";
import { testFeature } from "../test/test.feature";
import { Answer } from "./components/AnswerInput";
import { BaseUiEntity } from "app/classes/base-ui-entity";

export type EntitySchema = {
  id?: string;
  code: string;
  statement: string;
  questionTypeId: string;
  testDimensionId: string;
  answersOrder: string;
  answerJson: Answer[]
  questionType: QuestionTypeEntitySchema
  createdAt: string;
  updatedAt: string;
};

export const entityInitialState = {
  id: undefined,
  code: "",
  statement: "",
  questionTypeId: "",
  testDimensionId: "",
  answersOrder: "",
  answerJson: "",
  createdAt: "",
  updatedAt: "",
};

const singularName = "Pregunta"
const pluralName = "Preguntas"

const slug: string = "test-question";

const name: EntityName = {
  singular: singularName,
  plural: pluralName,
};

const primaryKeyAttribute: string = "id";

const linkedToViewAttribute: string = "name";

const attributes: EntityAttribute[] = [
  {
    key: "id",
    label: "ID",
    dataType: "string",
    showInTable: false
  },
  {
    key: "code",
    label: "Código",
    dataType: "string",
    showInTable: true
  },
  {
    key: "questionType.name",
    label: questionTypeFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "testDimension.name",
    label: testDimensionFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "testDimension.testVersion.name",
    label: testVersionFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "testDimension.testVersion.test.name",
    label: testFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "createdAt",
    label: "Creado",
    dataType: "date",
    showInTable: true
  },
  {
    key: "updatedAt",
    label: "Actualizado",
    dataType: "date",
    showInTable: false
  },
];

const join: JoinDefinition[] = [
  {
    entity: 'questionType',
    attributes: ['id', 'name', 'class']
  },
  {
    entity: 'testDimension',
    attributes: ['id', 'name']
  },
  {
    entity: 'testDimension.testVersion',
    attributes: ['id', 'name']
  },
  {
    entity: 'testDimension.testVersion.test',
    attributes: ['id', 'name']
  }
]

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
  constructor() {
    super(
      name,
      slug,
      primaryKeyAttribute,
      linkedToViewAttribute,
      attributes,
      join,
      joinAndCount,
    )
  }
}