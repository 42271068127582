import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { planFeature } from "../../plan.feature";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteAction, resetDeleteAction } from '../../plan.slice'
import { useEffect } from "react";
import ErrorAlert from "app/components/ErrorAlert";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (deleted: boolean) => void
}

const feature = planFeature

export default function Delete(props: Props) {
    const dispatch = useAppDispatch()
    const deleteSelector = useAppSelector(state => state.plan.delete)

    useEffect(() => {
        if (deleteSelector.done)
            props.onCompleted(deleteSelector.done)

        dispatch(resetDeleteAction())
    }, [deleteSelector.done])

    const handleConfirm = () => {
        dispatch(
            deleteAction({
                id: props.id
            })
        )
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Eliminar ${feature.label.singular}`}
            />
            <Divider />
            <CardContent>
                <Box>
                    <Typography>¿Seguro que desea eliminar { feature.article.definite.singular } { feature.label.singular }?, esta acción no se puede deshacer</Typography>
                </Box>
            </CardContent>
            <Divider />

            <ErrorAlert
                shown={Boolean(deleteSelector.error)}
                message={deleteSelector.error}
            />

            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={deleteSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    No, Cancelar
                </Button>
                <Button
                    disabled={deleteSelector.loading}
                    variant="contained"
                    onClick={handleConfirm}
                >
                    Si, Eliminar
                </Button>
            </CardActions>
        </Card>
    )
}
