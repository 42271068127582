import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { PlanService } from './plan.service'
import { EntitySchema } from './plan.entity'
import { ListDto } from './pages/List/list.dto'
import { CreateDto } from './pages/Create/create.dto'
import { DeleteDto } from './pages/Delete/delete.dto'
import { UpdateDto } from './pages/Update/update.dto'
import { initialActionState, initialCreateState, initialDeleteState, initialListState, initialUpdateState, initialViewState } from 'app/redux/helpers/state.helper'
import { AttachTestVersionsDto } from './dto/attach-test-versions.dto'
import { FindOneDto } from './dto/find-one.dto'
import { ContractService } from '../contract/contract.service'

const sliceName = 'plan'

const initialAutocompleteState = {
    ...initialActionState,
    data: [] as EntitySchema[]
}

const initialAttachTestVersionsState = initialActionState

const initialState = {
    list: initialListState<EntitySchema>(),
    view: initialViewState<EntitySchema>(),
    create: initialCreateState(),
    update: initialUpdateState(),
    delete: initialDeleteState(),
    autocomplete: initialAutocompleteState,
    attachTestVersions: initialAttachTestVersionsState
}

const findOneAction = createAsyncThunk(
    `${sliceName}/findOne`,
    async (dto: FindOneDto) => {
        const service = new PlanService()

        return service.findOne(dto)
    }
)

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    (input: ListDto) => {
        const service = new PlanService()

        return service.findAll(input)
    }
)

const createAction = createAsyncThunk(
    `${sliceName}/create`,
    (input: CreateDto) => {
        const service = new PlanService()

        return service.create(input)
    }
)

const updateAction = createAsyncThunk(
    `${sliceName}/update`,
    (input: { id: string, dto: UpdateDto }) => {
        const { id, dto } = input
        const service = new PlanService()

        return service.update(id, dto)
    }
)

const deleteAction = createAsyncThunk(
    `${sliceName}/delete`,
    async (input: DeleteDto, thunkAPI) => {
        const service = new PlanService()
        
        const plansResult = await new ContractService().findPlanByContract(input.id)

        if(!plansResult.ok) {
            return thunkAPI.rejectWithValue(plansResult.message)
        }

        const result = await service.delete(input)

        return result.ok ?
            thunkAPI.fulfillWithValue(result.message) :
            thunkAPI.rejectWithValue(result.message)
    }
)

const autocompleteAction = createAsyncThunk(
    `${sliceName}/autocomplete`,
    async (input: ListDto) => {
        const service = new PlanService()

        return service.findAll(input)
    }
)

const attachTestVersionsAction = createAsyncThunk(
    `${sliceName}/attachTestVersions`,
    (input: AttachTestVersionsDto) => {
        const service = new PlanService()

        return service.attachTestVersions(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetListAction: (state) => {
            state.list = initialState.list
        },
        resetViewAction: (state) => {
            state.view = initialState.view
        },
        resetCreateAction: (state) => {
            state.create = initialState.create
        },
        resetUpdateAction: (state) => {
            state.update = initialState.update
        },
        resetDeleteAction: (state) => {
            state.delete = initialState.delete
        },
        resetAutocompleteAction: (state) => {
            state.autocomplete = initialState.autocomplete
        },
        resetAttachTestVersionsAction: (state) => {
            state.attachTestVersions = initialAttachTestVersionsState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.done = false
                state.list.error = initialState.list.error
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.done = true
                state.list.error = initialState.list.error
                state.list.data = action.payload.data
                state.list.meta = action.payload.meta
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.done = false
                state.list.error = action.error.message || 'Someting wrong occured'
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })

            .addCase(findOneAction.pending, (state) => {
                state.view.loading = true;
                state.view.done = false
                state.view.error = initialState.view.error
                state.view.data = initialState.view.data
            })
            .addCase(findOneAction.fulfilled, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = initialState.view.error
                state.view.data = action.payload
            })
            .addCase(findOneAction.rejected, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = action.error.message || 'Someting wrong occured'
                state.view.data = initialState.view.data
            })

            .addCase(createAction.pending, (state) => {
                state.create.loading = true;
                state.create.done = false
                state.create.error = initialState.create.error
                state.create.id = initialState.create.id
            })
            .addCase(createAction.fulfilled, (state, action) => {
                state.create.loading = false;
                state.create.done = true
                state.create.error = initialState.create.error
                state.create.id = action.payload
            })
            .addCase(createAction.rejected, (state, action) => {
                state.create.loading = false;
                state.create.done = false
                state.create.error = action.error.message || 'Someting wrong occured'
                state.create.id = initialState.create.id
            })

            .addCase(updateAction.pending, (state) => {
                state.update.loading = true;
                state.update.done = false
                state.update.error = initialState.update.error
            })
            .addCase(updateAction.fulfilled, (state, action) => {
                state.update.loading = false;
                state.update.done = true
                state.update.error = initialState.update.error
            })
            .addCase(updateAction.rejected, (state, action) => {
                state.update.loading = false;
                state.update.done = false
                state.update.error = action.error.message || 'Someting wrong occured'
            })

            .addCase(deleteAction.pending, (state) => {
                state.delete.loading = true;
                state.delete.done = false
                state.delete.error = initialState.delete.error
            })
            .addCase(deleteAction.fulfilled, (state, action) => {
                state.delete.loading = false;
                state.delete.done = true
                state.delete.error = initialState.delete.error
            })
            .addCase(deleteAction.rejected, (state, action) => {
                state.delete.loading = false;
                state.delete.done = false
                state.delete.error = action.payload as string
            })

            .addCase(autocompleteAction.pending, (state) => {
                state.autocomplete.loading = true;
                state.autocomplete.done = false
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = initialState.autocomplete.data
            })
            .addCase(autocompleteAction.fulfilled, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = true
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = action.payload.data
            })
            .addCase(autocompleteAction.rejected, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = false
                state.autocomplete.error = action.error.message || 'Someting wrong occured'
                state.autocomplete.data = initialState.autocomplete.data
            })

            .addCase(attachTestVersionsAction.pending, (state) => {
                state.attachTestVersions.loading = true;
                state.attachTestVersions.error = ''
                state.attachTestVersions.done = false
            })
            .addCase(attachTestVersionsAction.fulfilled, (state, action) => {
                state.attachTestVersions.loading = false;
                state.attachTestVersions.error = ''
                state.attachTestVersions.done = true
            })
            .addCase(attachTestVersionsAction.rejected, (state, action) => {
                state.attachTestVersions.loading = false;
                state.attachTestVersions.error = action.error.message || 'Someting wrong occured'
                state.attachTestVersions.done = false
            })
    }
})

export {
    findOneAction,
    findAllAction,
    createAction,
    updateAction,
    deleteAction,
    autocompleteAction,
    attachTestVersionsAction
}

export const { resetListAction, resetViewAction, resetCreateAction, resetUpdateAction, resetDeleteAction, resetAutocompleteAction, resetAttachTestVersionsAction } = slice.actions
export const selector = (state: RootState) => state.plan
export default slice.reducer;
