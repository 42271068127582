import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import * as React from 'react';
import { useEffect } from 'react';
import { autoCompleteAction } from "../../question-type.slice";
import SelectInput from 'app/components/SelectInput';
import { PaginationDto } from 'app/types/pagination.dto';
import { SelectChangeEvent } from "@mui/material/Select";

type Props = {
    required?: boolean
    label: string
    name: string
    value?: string
    error?: string
    disabled?: boolean
    onChange?: (name: string, id: string, questionClass: string) => void
}

type Option = {
    label: string
    value: string
}

export default function QuestionTypeSelectInput(props: Props) {
    const dispatch = useAppDispatch();
    const autocompleteSelector = useAppSelector((state) => state.questionType.autocomplete);
    const [options, setOptions] = React.useState<Option[]>([])

    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        if (autocompleteSelector.done && !Boolean(autocompleteSelector.error)) {
            setOptions(autocompleteSelector.data.map(row => {
                return {
                    label: row.name,
                    value: row.id!
                }
            }))
        } else {
            setOptions([])
        }
    }, [autocompleteSelector])

    const refreshData = () => {
        let findAllOptions: PaginationDto = {
            page: 0,
            perPage: 0
        }

        dispatch(
            autoCompleteAction(findAllOptions)
        )
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        const questionType = autocompleteSelector.data.find(o => o.id === event.target.value)

        if(questionType && props.onChange) {
            props.onChange(
                event.target.name,
                questionType.id!,
                questionType.class
            )
        }
    }

    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={props.label}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={handleSelectChange}
        />
    )
}