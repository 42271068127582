import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthenticationService } from '../services/AuthenticationService'
import { RootState } from './store'
import { StorageService } from '../services/StorageService'
import { initialActionState } from './helpers/state.helper'
import { handleApiResponses } from './helpers/async-thunk.helper'

const sliceName = 'authenticator'

const userInitialState = {
    id: '',
    name: '',
    lastName: '',
    email: '',
    roles: [] as string[]
}

const authenticateInitialState = {
    ...initialActionState,
    authenticated: false,
    user: userInitialState,
    permissions: '',
}

const requestPasswordResetInitialState = {
    ...initialActionState
}

const resetPasswordInitialState = {
    ...initialActionState
}

const initialState = {
    authenticate: authenticateInitialState,
    authenticated: authenticateInitialState,
    requestPasswordReset: requestPasswordResetInitialState,
    resetPassword: resetPasswordInitialState
}

type authenticateInput = {
    email: string,
    password: string
}

type requestPasswordResetInput = {
    email: string
}

type resetPasswordInput = {
    password: string
    code: string
}

const authenticate = createAsyncThunk(
    `${sliceName}/authenticate`,
    async (input: authenticateInput, thunkApi) => {
        const authService = new AuthenticationService()
        const response = await authService.login(input.email, input.password)

        if (response.statusCode == 200 && response.result) {
            StorageService.setLoginData(response.result.token, JSON.stringify(response.result.user), response.result.permissions)
        }

        return handleApiResponses(response, thunkApi)
    }
)

const getAuthenticated = createAsyncThunk(
    `${sliceName}/getAuthenticated`,
    async () => {
        const user = StorageService.getUser()
        const permissions = StorageService.getPermissions()

        if (!user || !permissions) {
            throw new Error('user not logged in')
        }

        return {
            user: JSON.parse(user),
            permissions
        }
    }
)

const getPermissionsAndRoles = createAsyncThunk(
    `${sliceName}/getPermissionsAndRoles`,
    async () => {
        const authService = new AuthenticationService()
        const { user, permissions } = await authService.getPermissionsAndRoles()

        StorageService.setUser(JSON.stringify(user))
        StorageService.setPermissions(permissions)

        return { user, permissions }
    }
)

const requestPasswordReset = createAsyncThunk(
    `${sliceName}/requestPasswordReset`,
    async (input: requestPasswordResetInput) => {
        const authService = new AuthenticationService()
        await authService.requestPasswordReset(input.email)
    }
)

const resetPassword = createAsyncThunk(
    `${sliceName}/resetPassword`,
    async (input: resetPasswordInput) => {
        const authService = new AuthenticationService()
        await authService.resetPassword(input.password, input.code)
    }
)

const logout = createAsyncThunk(
    `${sliceName}/logout`,
    async () => {
        const authService = new AuthenticationService()
        authService.logout()
        window.location.reload()
    }
)

export const authenticatorSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authenticate.pending, (state) => {
                state.authenticate.loading = true
                state.authenticate.error = initialState.authenticate.error
                state.authenticate.done = initialState.authenticate.done
                state.authenticate.authenticated = initialState.authenticate.authenticated
                state.authenticate.user = initialState.authenticate.user
                state.authenticate.permissions = initialState.authenticate.permissions
            })
            .addCase(authenticate.fulfilled, (state, action) => {
                state.authenticate.loading = initialState.authenticate.loading
                state.authenticate.error = initialState.authenticate.error
                state.authenticate.done = true
                state.authenticate.authenticated = true
                state.authenticate.user = action.payload!.result!.user!;
                state.authenticate.permissions = action.payload!.result!.permissions;
            })
            .addCase(authenticate.rejected, (state, action) => {
                state.authenticate.loading = false
                state.authenticate.error = action.payload as string
                state.authenticate.done = false
                state.authenticate.authenticated = false
                state.authenticate.user = initialState.authenticate.user
                state.authenticate.permissions = initialState.authenticate.permissions
            })

            .addCase(getAuthenticated.pending, (state) => {
                state.authenticated.loading = true
                state.authenticated.error = initialState.authenticated.error
                state.authenticated.done = initialState.authenticated.done
                state.authenticated.authenticated = initialState.authenticated.authenticated
                state.authenticated.user = initialState.authenticated.user
                state.authenticated.permissions = initialState.authenticated.permissions
            })
            .addCase(getAuthenticated.fulfilled, (state, action) => {
                state.authenticated.loading = initialState.authenticated.loading
                state.authenticated.error = initialState.authenticated.error
                state.authenticated.done = true
                state.authenticated.authenticated = true
                state.authenticated.user = action.payload.user;
                state.authenticated.permissions = action.payload.permissions;
            })
            .addCase(getAuthenticated.rejected, (state, action) => {
                state.authenticate.loading = initialState.authenticate.loading
                state.authenticate.error = action.error.message || 'Someting wrong occured'
                state.authenticate.done = false
                state.authenticate.authenticated = false
                state.authenticate.user = initialState.authenticate.user
                state.authenticate.permissions = initialState.authenticate.permissions
            })

            .addCase(getPermissionsAndRoles.pending, (state) => {
                state.authenticated.loading = true
                state.authenticated.error = initialState.authenticated.error
                state.authenticated.done = initialState.authenticated.done
                state.authenticated.authenticated = initialState.authenticated.authenticated
                state.authenticated.user = initialState.authenticated.user
                state.authenticated.permissions = initialState.authenticated.permissions
            })
            .addCase(getPermissionsAndRoles.fulfilled, (state, action) => {
                state.authenticated.loading = initialState.authenticated.loading
                state.authenticated.error = initialState.authenticated.error
                state.authenticated.done = true
                state.authenticated.authenticated = true
                state.authenticated.user = action.payload.user;
                state.authenticated.permissions = action.payload.permissions;
            })
            .addCase(getPermissionsAndRoles.rejected, (state, action) => {
                state.authenticate.loading = initialState.authenticate.loading
                state.authenticate.error = action.error.message || 'Someting wrong occured'
                state.authenticate.done = false
                state.authenticate.authenticated = false
                state.authenticate.user = initialState.authenticate.user
                state.authenticate.permissions = initialState.authenticate.permissions
            })

            .addCase(requestPasswordReset.pending, (state) => {
                state.requestPasswordReset.loading = true;
                state.requestPasswordReset.error = requestPasswordResetInitialState.error
                state.requestPasswordReset.done = false
            })
            .addCase(requestPasswordReset.fulfilled, (state, action) => {
                state.requestPasswordReset.loading = false;
                state.requestPasswordReset.error = requestPasswordResetInitialState.error
                state.requestPasswordReset.done = true
            })
            .addCase(requestPasswordReset.rejected, (state, action) => {
                state.requestPasswordReset.loading = false;
                state.requestPasswordReset.error = action.error.message || 'Someting wrong occured'
                state.requestPasswordReset.done = false
            })

            .addCase(resetPassword.pending, (state) => {
                state.resetPassword.loading = true;
                state.resetPassword.error = resetPasswordInitialState.error
                state.resetPassword.done = false
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.resetPassword.loading = false;
                state.resetPassword.error = resetPasswordInitialState.error
                state.resetPassword.done = true
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.resetPassword.loading = false;
                state.resetPassword.error = action.error.message || 'Someting wrong occured'
                state.resetPassword.done = true
            })

            .addCase(logout.pending, (state) => {
                state.authenticate = authenticateInitialState
            })
            .addCase(logout.fulfilled, (state) => {
                state.authenticate = authenticateInitialState
            })
            .addCase(logout.rejected, (state) => {
                state.authenticate = authenticateInitialState
            })
    }
})

export {
    authenticate,
    getAuthenticated,
    getPermissionsAndRoles,
    requestPasswordReset,
    resetPassword,
    logout
}

export const authenticatorSelector = (state: RootState) => state.authenticator
export default authenticatorSlice.reducer;
