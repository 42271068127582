import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { UserEmployeeService } from './user-employee.service'
import { EntitySchema } from './user-employee.entity'
import { CreateDto } from './pages/Create/create.dto'
import { UpdateInfo, UpdateInfoDto } from './pages/UpdateInfo/update-info.dto'
import { ListDto } from './pages/List/list.dto'
import { DeleteDto } from "./pages/Delete/delete.dto";
import { UpdatePassword, UpdatePasswordDto } from './pages/UpdatePassword/update-password.dto'
import { AttachRbacRolesDto } from './dto/attach-roles.dto'
import { FindOneDto } from './dto/find-one.dto'
import { AttachOrganizationalUnitsDto } from './dto/attach-organizational-units.dto'
import { initialActionState, initialCreateState, initialDeleteState, initialListState, initialViewState } from 'app/redux/helpers/state.helper'
import { handleApiResponses } from 'app/redux/helpers/async-thunk.helper'

const sliceName = 'employee'

const initialUpdateInfo: UpdateInfoDto = {
    name: '',
    lastName: '',
    email: '',
    organizationId: ''
}

const initialUpdatePassword: UpdatePasswordDto = {
    password: ''
}

const initialUpdateInfoState = { ...initialActionState, data: initialUpdateInfo }
const initialUpdatePasswordState = { ...initialActionState, data: initialUpdatePassword }
const initialAttachRbacRolesState = initialActionState
const initialAttachOrganizationalUnitRoles = initialActionState
const initialAutocompleteState = {
    ...initialActionState,
    data: [] as EntitySchema[]
}

export const initialState = {
    create: initialCreateState(),
    view: initialViewState<EntitySchema>(),
    list: initialListState<EntitySchema>(),
    updateInfo: initialUpdateInfoState,
    updatePassword: initialUpdatePasswordState,
    delete: initialDeleteState(),
    attachRbacRoles: initialAttachRbacRolesState,
    attachOrganizationalUnitRoles: initialAttachOrganizationalUnitRoles,
    autocomplete: initialAutocompleteState
}

const findOneAction = createAsyncThunk(
    `${sliceName}/findOne`,
    async (input: FindOneDto) => {
        const service = new UserEmployeeService()

        return service.findOne(input)
    }
)

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    async (input: ListDto) => {
        const service = new UserEmployeeService()

        return service.findAll(input)
    }
)

const createAction = createAsyncThunk(
    `${sliceName}/create`,
    async (input: CreateDto, thunkApi) => {
        const service = new UserEmployeeService()
        const result = await service.create(input)

        return handleApiResponses(result, thunkApi)
    }
)

const deleteAction = createAsyncThunk(
    `${sliceName}/delete`,
    (input: DeleteDto) => {
        const service = new UserEmployeeService()

        return service.delete(input)
    }
)

const updateInfoAction = createAsyncThunk(
    `${sliceName}/updateInfo`,
    async (input: UpdateInfo) => {
        const { id, dto } = input
        const service = new UserEmployeeService()

        return service.updateInfo(id, dto)
    }
)

const updatePasswordAction = createAsyncThunk(
    `${sliceName}/updatePassword`,
    async (input: UpdatePassword) => {
        const { id, dto } = input
        const service = new UserEmployeeService()

        return service.updatePassword(id, dto)
    }
)

const attachRbacRolesAction = createAsyncThunk(
    `${sliceName}/attachRbacRoles`,
    (input: AttachRbacRolesDto) => {
        const service = new UserEmployeeService()

        return service.attachRbacRoles(input)
    }
)

const attachOrganizationalUnitsAction = createAsyncThunk(
    `${sliceName}/attachOrganizationalUnits`,
    (input: AttachOrganizationalUnitsDto) => {
        const service = new UserEmployeeService()

        return service.attachOrganizationalUnits(input)
    }
)

const autocompleteAction = createAsyncThunk(
    `${sliceName}/autocomplete`,
    async (id: string) => {
        const service = new UserEmployeeService()
        return await service.findAllByNameAndLastName(id)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetDataTableAction: (state) => {
            state.list = initialListState()
        },
        resetCreateAction: (state) => {
            state.create = initialCreateState()
        },
        resetDeleteAction: (state) => {
            state.delete = initialDeleteState()
        },
        resetUpdateInfoAction: (state) => {
            state.updateInfo = initialUpdateInfoState
        },
        resetUpdatePasswordAction: (state) => {
            state.updatePassword = initialUpdatePasswordState
        },
        resetAttachRbacRolesAction: (state) => {
            state.attachRbacRoles = initialAttachRbacRolesState
        },
        resetAttachOrganizationalUnitsAction: (state) => {
            state.attachOrganizationalUnitRoles = initialAttachOrganizationalUnitRoles
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAction.pending, (state) => {
                state.create.loading = true;
                state.create.error = ''
                state.create.done = false
                state.create.id = undefined
            })
            .addCase(createAction.fulfilled, (state, action) => {
                state.create.loading = false;
                state.create.error = ''
                state.create.done = true
                state.create.id = action.payload?.result
            })
            .addCase(createAction.rejected, (state, action) => {
                state.create.loading = false;
                state.create.error = action.payload as string
                state.create.done = false
                state.create.id = undefined
            })

            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.error = "";
                state.list.data = []
                state.list.meta = initialState.list.meta
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.error = "";
                state.list.data = action.payload.data;
                state.list.meta = action.payload.meta;
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.error = action.error.message || "Someting wrong occured";
                state.list.data = []
                state.list.meta = initialState.list.meta
            })

            .addCase(findOneAction.pending, (state) => {
                state.view.loading = true;
                state.view.error = ''
                state.view.data = undefined
            })
            .addCase(findOneAction.fulfilled, (state, action) => {
                state.view.loading = false;
                state.view.error = ''
                state.view.data = action.payload
            })
            .addCase(findOneAction.rejected, (state, action) => {
                state.view.loading = false;
                state.view.error = action.error.message || 'Someting wrong occured'
                state.view.data = undefined
            })

            .addCase(updateInfoAction.pending, (state) => {
                state.updateInfo.loading = true;
                state.updateInfo.error = ''
                state.updateInfo.done = false
            })
            .addCase(updateInfoAction.fulfilled, (state, action) => {
                state.updateInfo.loading = false;
                state.updateInfo.error = ''
                state.updateInfo.done = action.payload
            })
            .addCase(updateInfoAction.rejected, (state, action) => {
                state.updateInfo.loading = false;
                state.updateInfo.error = action.error.message || 'Someting wrong occured'
                state.updateInfo.done = false
            })

            .addCase(updatePasswordAction.pending, (state) => {
                state.updatePassword.loading = true;
                state.updatePassword.error = ''
                state.updatePassword.done = false
            })
            .addCase(updatePasswordAction.fulfilled, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = ''
                state.updatePassword.done = action.payload
            })
            .addCase(updatePasswordAction.rejected, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = action.error.message || 'Someting wrong occured'
                state.updatePassword.done = false
            })

            .addCase(deleteAction.pending, (state) => {
                state.delete.loading = true;
                state.delete.error = ''
                state.delete.done = false
            })
            .addCase(deleteAction.fulfilled, (state, action) => {
                state.delete.loading = false;
                state.delete.error = ''
                state.delete.done = action.payload
            })
            .addCase(deleteAction.rejected, (state, action) => {
                state.delete.loading = false;
                state.delete.error = action.error.message || 'Someting wrong occured'
                state.delete.done = false
            })

            .addCase(attachRbacRolesAction.pending, (state) => {
                state.attachRbacRoles.loading = true;
                state.attachRbacRoles.error = ''
                state.attachRbacRoles.done = false
            })
            .addCase(attachRbacRolesAction.fulfilled, (state, action) => {
                state.attachRbacRoles.loading = false;
                state.attachRbacRoles.error = ''
                state.attachRbacRoles.done = action.payload
            })
            .addCase(attachRbacRolesAction.rejected, (state, action) => {
                state.attachRbacRoles.loading = false;
                state.attachRbacRoles.error = action.error.message || 'Someting wrong occured'
                state.attachRbacRoles.done = false
            })

            .addCase(attachOrganizationalUnitsAction.pending, (state) => {
                state.attachOrganizationalUnitRoles.loading = true;
                state.attachOrganizationalUnitRoles.error = ''
                state.attachOrganizationalUnitRoles.done = false
            })
            .addCase(attachOrganizationalUnitsAction.fulfilled, (state, action) => {
                state.attachOrganizationalUnitRoles.loading = false;
                state.attachOrganizationalUnitRoles.error = ''
                state.attachOrganizationalUnitRoles.done = action.payload
            })
            .addCase(attachOrganizationalUnitsAction.rejected, (state, action) => {
                state.attachOrganizationalUnitRoles.loading = false;
                state.attachOrganizationalUnitRoles.error = action.error.message || 'Someting wrong occured'
                state.attachOrganizationalUnitRoles.done = false
            })

            .addCase(autocompleteAction.pending, (state) => {
                state.autocomplete.loading = true;
                state.autocomplete.error = ''
                state.autocomplete.done = false
            })
            .addCase(autocompleteAction.fulfilled, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.error = ''
                state.autocomplete.done = true
                state.autocomplete.data = action.payload
            })
            .addCase(autocompleteAction.rejected, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.error = action.error.message || 'Someting wrong occured'
                state.autocomplete.done = false
            })
    }
})

export {
    findOneAction,
    findAllAction,
    createAction,
    deleteAction,
    updateInfoAction,
    updatePasswordAction,
    attachRbacRolesAction,
    attachOrganizationalUnitsAction,
    autocompleteAction
}

export const {
    resetDataTableAction,
    resetCreateAction,
    resetDeleteAction,
    resetUpdateInfoAction,
    resetUpdatePasswordAction,
    resetAttachRbacRolesAction,
    resetAttachOrganizationalUnitsAction
} = slice.actions

export const selector = (state: RootState) => state.userEmployee
export default slice.reducer;
