import Joi from "joi";
import { ContractStatus } from "../../contract.entity";
import dayjs from "dayjs";

export const schema = (minDate: string) => Joi.object<any, true>({
    status: Joi
        .string()
        .valid(ContractStatus.REVIEW)
        .valid(ContractStatus.ON_HOLD)
        .valid(ContractStatus.ENABLED)
        .valid(ContractStatus.FINISHED)
        .valid(ContractStatus.CANCELLED)
        .valid(ContractStatus.DISABLED)
        .required(),
    issueDate: Joi
        .date()
        .min('now')
        .label('Fecha de inicio')
        .required()
        .messages({
            'date.min': '"Fecha de inicio" debe ser mínimo el dia de hoy'
        }),
    expirationDate: Joi
        .date()
        .min(dayjs(minDate).add(1, 'day').toString())
        .label('Fecha de expiración')
        .required()
        .messages({
            'date.min': '"Fecha de expiración" debe ser mínimo 24 horas después de la fecha de inicio'
        }),
    maxEmployeesNum: Joi
        .number()
        .min(0)
        .required(),
    price: Joi
        .number()
        .min(0)
        .required(),
})
