import { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from "app/redux/hooks";
import * as React from 'react';
import { useEffect } from 'react';
import { autoCompleteAction } from "../../test-dimension.slice";
import SelectInput from 'app/components/SelectInput';
import { PaginationDto } from 'app/types/pagination.dto';

type Props = {
    required?: boolean
    label: string
    name: string
    value?: string
    testVersionId?: string
    error?: string
    disabled?: boolean
    onChange?: (event: SelectChangeEvent) => void
}

type Option = {
    label: string
    value: string
}

export default function TestDimensionSelectInput(props: Props) {
    const dispatch = useAppDispatch();
    const autocompleteSelector = useAppSelector((state) => state.testDimension.autocomplete);
    const [options, setOptions] = React.useState<Option[]>([])

    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        refreshData()
    }, [props.testVersionId])

    useEffect(() => {
        if (autocompleteSelector.done && !Boolean(autocompleteSelector.error)) {
            setOptions(autocompleteSelector.data.map(row => {
                return {
                    label: `${row.testVersion?.test?.name} ${row.testVersion?.name} - ${row.name}`,
                    value: row.id!
                }
            }))
        } else {
            setOptions([])
        }
    }, [autocompleteSelector])

    const refreshData = () => {
        let findAllOptions: PaginationDto = {
            page: 0,
            perPage: 0,
            join: 'testVersion,testVersion.test'
        }

        if (props.testVersionId) {
            findAllOptions.filter = `testVersionId:${props.testVersionId}`
        }

        dispatch(
            autoCompleteAction(findAllOptions)
        )
    }

    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={props.label}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={props.onChange}
        />
    )
}