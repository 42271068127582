import BusinessIcon from '@mui/icons-material/Business';
import { OrganizationEntity } from './organization.entity';
import { Feature } from '../../types/feature.type';
import { feminineArticle } from '../../features/articles';
import { AuthorizationService } from 'app/services/AuthorizationService';

const entity = new OrganizationEntity()

export const organizationFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: feminineArticle,
    icon: BusinessIcon,
    isAuthorized: AuthorizationService.userIsAuthorized('organization:findAll'),
    entryLink: `/backoffice/${entity.slug}`,
}
