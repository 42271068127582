import { Feature } from '../../types/feature.type';
import RuleIcon from '@mui/icons-material/Rule';
import { masculineArticle } from '../../features/articles';
import { Entity } from './statement.entity';
import { AuthorizationService } from 'app/services/AuthorizationService';

const entity = new Entity()

export const statementFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: masculineArticle,
    icon: RuleIcon,
    isAuthorized: AuthorizationService.userIsAuthorized('rbac-statement:findAll'),
    entryLink: `/backoffice/${entity.slug}`,
}
