import LabelIcon from '@mui/icons-material/Label';
import { Feature } from '../../types/feature.type';
import { feminineArticle } from '../../features/articles';
import { Entity } from './test-question.entity';
import { AuthorizationService } from 'app/services/AuthorizationService';

const entity = new Entity()

export const testQuestionFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural
    },
    menu: {
        grammaticalNumber: 'plural'
    },
    article: feminineArticle,
    icon: LabelIcon,
    isAuthorized: AuthorizationService.userIsAuthorized('test-question:findAll'),
    entryLink: `/backoffice/${entity.slug}`,
}
