import { SelectChangeEvent } from '@mui/material/Select';
import SelectInput from 'app/components/SelectInput';

type Props = {
    label: string
    name: string
    value?: string
    error?: string
    onChange?: (event: SelectChangeEvent) => void
}

type Option = {
    label: string
    value: string
}

const options: Option[] = [
    {
        label: 'Múltiples opciones, una respuesta',
        value: 'MULTIPLE_OPTIONS_ONE_ANSWER'
    },
    {
        label: 'Múltiples opciones, múltiples respuestas',
        value: 'MULTIPLE_OPTIONS_MULTIPLE_ANSWER'
    },
    {
        label: 'Múltiples opciones con ordenamiento',
        value: 'MULTIPLE_OPTIONS_SORTING'
    }
]

export default function QuestionTypeClassSelectInput(props: Props) {
    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={props.label}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={props.onChange}
        />
    )
}