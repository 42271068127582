import { useEffect, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction } from '../../plan.slice';
import Create from '../Create';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import Update from '../Update';
import PageHeader from '../../../../components/PageHeader';
import { PlanEntity, EntitySchema } from '../../plan.entity';
import { planFeature } from '../../plan.feature';
import FormDialog from '../../../../components/FormDialog';
import Delete from '../Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { FilterState } from 'app/types/pagination.dto';

const feature = planFeature
const entity = new PlanEntity()

const filterInitialState: FilterState = {
    state: [],
    query: undefined,
}

export default function List() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const listSelector = useAppSelector(state => state.plan.list)
    const deleteSelector = useAppSelector(state => state.plan.delete);
    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<{ state: MRT_SortingState, query?: string }>()
    const [filter, setFilter] = useState<{ state: MRT_ColumnFiltersState, query?: string }>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: listSelector.meta.page,
        pageSize: listSelector.meta.perPage
    })

    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [createEnabled, setCreateEnabled] = useState(false)
    const [updateEnabled, setUpdateEnabled] = useState(false)
    const [deleteEnabled, setDeleteEnabled] = useState(false)

    const [refreshVisible, setRefreshVisible] = useState(false)
    const [createVisible, setCreateVisible] = useState(false)
    const [updateVisible, setUpdateVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query
        }))
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('plan:findAll')

        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)

        setCreateVisible(AuthorizationService.userIsAuthorized('plan:create'))
        setUpdateVisible(AuthorizationService.userIsAuthorized('plan:update'))
        setDeleteVisible(AuthorizationService.userIsAuthorized('plan:create'))
    }, [])

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done]);

    useEffect(() => {
        setCreateEnabled(!selectedRow)
        setUpdateEnabled(!!selectedRow)
        setDeleteEnabled(!!selectedRow)
    }, [selectedRow])

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleCreateCancelled = () => {
        setCreateModalOpen(false)
    }

    const handleCreateCompleted = (id?: string) => {
        if (id) {
            setCreateModalOpen(false)
            refreshData()
        }
    }

    const handleUpdateCancelled = () => {
        setUpdateModalOpen(false)
    }

    const handleUpdateCompleted = (updated: boolean, data?: EntitySchema) => {
        if (updated) {
            setUpdateModalOpen(false)
            refreshData()
        }
    }

    const handleDeleteCancelled = () => {
        setDeleteModalOpen(false)
    }

    const handleDeleteCompleted = (deleted: boolean) => {
        if (deleted) {
            setDeleteModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Crear',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateEnabled,
            isVisible: updateVisible,
            onClick: () => setUpdateModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            <FormDialog open={createModalOpen}>
                <Create
                    onCancelled={handleCreateCancelled}
                    onCompleted={handleCreateCompleted}
                />
            </FormDialog>

            {
                selectedRow && (
                    <>
                        <FormDialog open={updateModalOpen}>
                            <Update
                                id={selectedRow?.id || ''}
                                onCancelled={handleUpdateCancelled}
                                onCompleted={handleUpdateCompleted}
                            />
                        </FormDialog>

                        <FormDialog open={deleteModalOpen}>
                            <Delete
                                id={selectedRow?.id || ''}
                                onCancelled={handleDeleteCancelled}
                                onCompleted={handleDeleteCompleted}
                            />
                        </FormDialog>
                    </>
                )
            }
        </>
    );
}
