import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function MenuHeader() {
    return (
        <Toolbar>
            <Typography align='center' width={'100%'}>
                Organization Name
            </Typography>
        </Toolbar>
    )
}