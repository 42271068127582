import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, resetUpdateAction, findOneAction } from '../../statement.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../statement.entity";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState = {
    name: '',
    key: ''
}

const errorsInitialState = formInitialState
const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const getStatementSelector = useAppSelector(state => state.statement.view)
    const updateStatementSelector = useAppSelector(state => state.statement.update)
    const [updateStatementForm, setUpdateStatementForm] = useState<UpdateDto>(formInitialState)
    const [updateStatementFormErrors, setUpdateStatementFormErrors] = useState<UpdateDto>(errorsInitialState)

    useEffect(() => {
        dispatch(findOneAction(props.id))
    }, [])

    useEffect(() => {
        if (!getStatementSelector.data) return

        setUpdateStatementForm({
            name: getStatementSelector.data.name,
            key: getStatementSelector.data.key
        })
    }, [getStatementSelector.data])

    useEffect(() => {
        if (updateStatementSelector.done) {
            dispatch(resetUpdateAction())
            props.onCompleted(updateStatementSelector.done)
        }
    }, [updateStatementSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateStatementForm({
            ...updateStatementForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: updateStatementForm.name,
            key: updateStatementForm.key
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            setUpdateStatementFormErrors({
                ...errorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={updateStatementForm.name ? updateStatementForm.name : ''}
                        onChange={handleInputChange}
                        error={Boolean(updateStatementFormErrors.name)}
                        helperText={updateStatementFormErrors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="key"
                        label="Llave"
                        value={updateStatementForm.key}
                        onChange={handleInputChange}
                        error={Boolean(updateStatementFormErrors.key)}
                        helperText={updateStatementFormErrors.key}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateStatementSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateStatementSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
