import Joi from "joi";
import { CreateDto } from "./create.dto";
import { testFeature } from "app/modules/test/test.feature";

export const schema = Joi.object<CreateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    description: Joi
        .string()
        .label('Descripción')
        .required(),
    uiUrl: Joi
        .string()
        .label('Url para resolver la prueba')
        .required(),
    testId: Joi
        .string()
        .label(testFeature.label.singular)
        .uuid()
        .required(),
})
