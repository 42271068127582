import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { ListDto } from "./pages/List/list.dto";
import { UpdateInfoDto } from "./pages/UpdateInfo/update-info.dto";
import { UpdatePasswordDto } from "./pages/UpdatePassword/update-password.dto";
import { EntitySchema } from "./user-employee.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { AttachRbacRolesDto } from "./dto/attach-roles.dto";
import { FindOneDto } from "./dto/find-one.dto";
import { AttachOrganizationalUnitsDto } from "./dto/attach-organizational-units.dto";
import { ApiResponse } from "app/types/api-response";
import { handleAxiosError } from "app/helpers/axios.helper";

export class UserEmployeeService extends ProtectedService {

    private path = '/user-employee'

    async findOne(dto: FindOneDto): Promise<EntitySchema> {
        const response = await this.axios.get(`${this.path}/${dto.id}`, {
            params: {
                attributes: dto.attributes,
                join: dto.join
            }
        })

        return response.data
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get(`${this.path}`, {
            params: dto
        })

        return response.data
    }

    async findAllByNameAndLastName(id: string) {
        const response = await this.axios.get(`profile/organizational-unit/${id}`)

        return response.data
    }

    async create(dto: CreateDto): Promise<ApiResponse<string>> {
        try {
            const response = await this.axios.post(`${this.path}`, dto)

            return {
                statusCode: response.status,
                result: response.data.id
            }
        } catch (error) {
            return handleAxiosError(error)
        }
    }

    async delete(dto: DeleteDto): Promise<boolean> {
        const response = await this.axios.delete(`${this.path}/${dto.id}`)

        return response.status === 200
    }

    async updateInfo(id: string, dto: UpdateInfoDto): Promise<boolean> {
        const response = await this.axios.patch(`${this.path}/${id}/info`, dto)

        return response.status === 200
    }

    async updatePassword(id: string, dto: UpdatePasswordDto): Promise<boolean> {
        const response = await this.axios.patch(`${this.path}/${id}/password`, dto)

        return response.status === 200
    }

    async attachRbacRoles(dto: AttachRbacRolesDto) {
        const response = await this.axios.patch(`${this.path}/${dto.id}/rbac-roles`, {
            roleIds: dto.roleIds
        })

        return response.status === 200
    }

    async attachOrganizationalUnits(dto: AttachOrganizationalUnitsDto) {
        const response = await this.axios.patch(`${this.path}/${dto.profileId}/organizational-units`, {
            attachments: dto.attachments
        })

        return response.status === 200
    }
}
