import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/redux/hooks"
import { findAllAction } from "../../test-version.slice";
import CheckBoxGroupInput from "app/components/CheckBoxGroupInput"
import { PaginationDto } from "app/types/pagination.dto";

type Props = {
    required?: boolean
    label: string
    name: string
    value: string[]
    planId?: string
    contractId?: string
    error?: string
    disabled?: boolean
    onChange?: (name: string, value: string[]) => void
}

export default function TestVersionCheckBoxGroupInput(props: Props) {
    const dispatch = useAppDispatch();
    const listSelector = useAppSelector((state) => state.testVersion.list);

    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = () => {
        let dto: PaginationDto = {
            page: 0,
            perPage: 0,
            join: 'test:id|name'
        }

        if (props.planId) {
            dto.join = `plans:id`
            dto.filter = `plans.id:${props.planId}`
        }

        if (props.contractId) {
            dto.join = `contracts:id`
            dto.filter = `contracts.id:${props.contractId}`
        }

        dispatch(findAllAction(dto))
    }

    return (
        <CheckBoxGroupInput
            label={props.label}
            name={props.name}
            value={props.value}
            list={listSelector.data.map(item => ({
                id: item.id!,
                label: `${item.test?.name} - ${item.name}`
            }))}
            onChange={props.onChange}
        />
    )
}
