import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { testQuestionFeature } from "../../test-question.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction } from '../../test-question.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import WysiwygInput from "app/components/WysiwygInput";
import QuestionTypeSelectInput from "app/modules/question-type/components/QuestionTypeSelectInput";
import { questionTypeFeature } from "app/modules/question-type/question-type.feature";
import { SelectChangeEvent } from "@mui/material/Select";
import TestDimensionSelectInput from "app/modules/test-dimension/components/TestDimensionSelectInput";
import { testDimensionFeature } from "app/modules/test-dimension/test-dimension.feature";
import AnswerInput, { Answer } from "../../components/AnswerInput";
import SelectInput, { SelectInputOption } from "app/components/SelectInput";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const initialState: CreateDto = {
    code: '',
    statement: '',
    questionTypeId: '',
    testDimensionId: '',
    answersOrder: '',
    answerJson: []
}

const initialErrorState = {
    code: '',
    statement: '',
    questionTypeId: '',
    testDimensionId: '',
    answersOrder: '',
    answerJson: ''
}

const feature = testQuestionFeature

const answersOrderOptions: SelectInputOption[] = [
    {
        label: 'Estático',
        value: 'STATIC'
    },
    {
        label: 'Aleatorio',
        value: 'RANDOM'
    }
]

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createSelector = useAppSelector(state => state.testQuestion.create)

    const [form, setForm] = useState<CreateDto>(initialState)
    const [formError, setFormError] = useState(initialErrorState)

    const [questionClass, setQuestionClass] = useState('')

    useEffect(() => {
        if (createSelector.done && Boolean(createSelector.error) === false) {
            props.onCompleted(createSelector.done)
            dispatch(resetCreateAction())
        }
    }, [createSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleQuestionTypeSelectInputChange = (name: string, id: string, questionClass: string) => {
        setForm({
            ...form,
            [name]: id
        })

        setQuestionClass(questionClass)
    }

    const handleWysiwygChange = (name: string, value: string) => {
        setForm({
            ...form,
            [name]: value
        })
    }
    const handleAnswerInputChange = (answers: Answer[]) => {
        setForm({
            ...form,
            answerJson: answers,
        })
    }

    const handleFormSubmit = () => {
        setFormError(initialErrorState)
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setFormError({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(createAction(form))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="code"
                        label="Código"
                        value={form.code}
                        onChange={handleInputChange}
                        error={Boolean(formError.code)}
                        helperText={formError.code}
                    />

                    <WysiwygInput
                        fullWidth
                        name="statement"
                        label="Enunciado"
                        value={form.statement}
                        error={formError.statement}
                        onChange={handleWysiwygChange}
                    />

                    <TestDimensionSelectInput
                        name="testDimensionId"
                        label={testDimensionFeature.label.singular}
                        value={form.testDimensionId}
                        error={formError.testDimensionId}
                        onChange={handleSelectChange}
                    />

                    <QuestionTypeSelectInput
                        name="questionTypeId"
                        label={questionTypeFeature.label.singular}
                        value={form.questionTypeId}
                        error={formError.questionTypeId}
                        onChange={handleQuestionTypeSelectInputChange}
                    />

                    <SelectInput
                        fullWidth
                        name="answersOrder"
                        label="Ordenamiento de las preguntas"
                        value={form.answersOrder}
                        options={answersOrderOptions}
                        error={formError.answersOrder}
                        onChange={handleSelectChange}
                    />

                    <AnswerInput
                        questionClass={questionClass}
                        name="answerJson"
                        label="Respuesta"
                        value={form.answerJson}
                        error={formError.answerJson}
                        onChange={handleAnswerInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
