import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { questionTypeFeature } from "../../question-type.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction } from '../../question-type.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import { SelectChangeEvent } from "@mui/material";
import { useQuery } from "app/hooks";
import TestVersionSelectInput from "app/modules/test-version/components/TestVersionSelectInput";
import QuestionTypeSelectInput from "../../components/QuestionTypeSelectInput";
import QuestionTypeClassSelectInput from "../../components/QuestionTypeClassSelectInput";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const initialState: CreateDto = {
    name: '',
    description: '',
    class: ''
}

const feature = questionTypeFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createSelector = useAppSelector(state => state.questionType.create)

    const [form, setForm] = useState<CreateDto>(initialState)
    const [formError, setFormError] = useState<CreateDto>(initialState)

    useEffect(() => {
        if (createSelector.done && Boolean(createSelector.error) === false) {
            props.onCompleted(createSelector.done)
            dispatch(resetCreateAction())
        }
    }, [createSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleFormSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setFormError({
                ...initialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(formError.name)}
                        helperText={formError.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="description"
                        label="Descripción"
                        multiline
                        rows={3}
                        value={form.description}
                        onChange={handleInputChange}
                        error={Boolean(formError.description)}
                        helperText={formError.description}
                    />

                    <QuestionTypeClassSelectInput
                        label="Clase de pregunta"
                        name="class"
                        value={form.class}
                        error={formError.class}
                        onChange={handleSelectChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
