import dayjs from "dayjs"
import { EntityAttribute, EntityName, UiEntity } from "../../interfaces/EntityInterface"
import { JoinDefinition } from "../../types/join.type"
import { organizationFeature } from "../organization/organization.feature"

export enum ContractStatus {
    REVIEW = 'REVIEW',
    ON_HOLD = 'ON_HOLD',
    ENABLED = 'ENABLED',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    DISABLED = 'DISABLED',
}

export type EntitySchema = {
    id: string
    status: string
    maxEmployeesNum: number
    price: number
    issueDate: string
    expirationDate: string
    organizationId: string
    createdAt: string
    updatedAt: string
}

export const entityInitialState: EntitySchema = {
    id: '',
    status: '',
    maxEmployeesNum: 0,
    price: 0,
    issueDate: "",
    expirationDate: "",
    organizationId: "",
    createdAt: '',
    updatedAt: '',
}

export class ContractEntity implements UiEntity {
    name: EntityName = {
        singular: 'Contrato',
        plural: 'Contratos'
    }

    slug = 'contract'

    primaryKeyAttribute: string = 'id'

    linkedToViewAttribute: string = 'name'

    attributes: EntityAttribute[] = [
        {
            key: 'id',
            label: 'ID',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'organization.name',
            label: organizationFeature.label.singular,
            dataType: 'string',
            showInTable: true,
            filter: false
        },
        {
            key: 'status',
            label: 'Estado',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'issueDate',
            label: 'Fecha de inicio',
            dataType: 'date',
            showInTable: true,
            accessorFn: (row) => dayjs(row.issueDate).locale('es').format('MMMM D, YYYY')
        },
        {
            key: 'expirationDate',
            label: 'Fecha de término',
            dataType: 'date',
            showInTable: true,
            accessorFn: (row) => dayjs(row.expirationDate).locale('es').format('MMMM D, YYYY')
        },
        {
            key: 'maxEmployeesNum',
            label: 'Número máximo de colaboradores',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'price',
            label: 'Valor en COP',
            dataType: 'currency',
            showInTable: true,
        },
    ]

    join: JoinDefinition[] = [
        {
            entity: 'organization',
            attributes: ['id', 'name']
        }
    ]
}
