import { JoinDefinition } from "app/types/join.type";
import {
  EntityAttribute,
  EntityName
} from "../../interfaces/EntityInterface";
import { EntitySchema as TestVersionEntitySchema } from "../test-version/test-version.entity"
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { BaseUiEntity } from "app/classes/base-ui-entity";
import { testFeature } from "../test/test.feature";
import { testVersionFeature } from "../test-version/test-version.feature";

export type EntitySchema = {
  id?: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  testDimensionId?: string;
  testDimension?: EntitySchema
  testVersionId: string;
  testVersion?: TestVersionEntitySchema
};

export const entityInitialState = {
  id: undefined,
  name: "",
  description: "",
  createdAt: "",
  updatedAt: "",
  testDimensionId: undefined,
  testDimension: undefined,
  testVersionId: "",
  testVersion: undefined
};

const singularName = "Dimensión"
const pluralName = "Dimensiones"

const slug: string = "test-dimension";

const name: EntityName = {
  singular: singularName,
  plural: pluralName,
};

const primaryKeyAttribute: string = "id";

const linkedToViewAttribute: string = "name";

const attributes: EntityAttribute[] = [
  {
    key: "testVersion.test.name",
    label: testFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "testVersion.name",
    label: testVersionFeature.label.singular,
    dataType: "string",
    showInTable: true
  },
  {
    key: "testDimension.name",
    label: `${singularName} padre`,
    dataType: "string",
    showInTable: true
  },
  {
    key: "name",
    label: "Nombre",
    dataType: "string",
    showInTable: true
  },
  {
    key: "description",
    label: "Descripción",
    dataType: "string",
    showInTable: true
  },
  {
    key: "testDimensionsCount",
    label: pluralName,
    dataType: "string",
    showInTable: true
  },
  {
    key: "createdAt",
    label: "Creado",
    dataType: "date",
    showInTable: true
  },
  {
    key: "updatedAt",
    label: "Actualizado",
    dataType: "date",
    showInTable: false
  },
];

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
  constructor() {
    super(
      name,
      slug,
      primaryKeyAttribute,
      linkedToViewAttribute,
      attributes,
      join,
      joinAndCount,
    )
  }
}
