import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { policyFeature } from "../../policy.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, attachStatementsAction, resetCreateAction } from '../../policy.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import StatementsCheckBoxGroupInput from "app/modules/statement/components/StatementsCheckBoxGroupInput";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const createPolicyInitialState: CreateDto = {
    name: ''
}

const statementsInitialState = {
    statementIds: []
}

const statementsErrorsInitialState = {
    statementIds: ''
}

const feature = policyFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createPolicySelector = useAppSelector(state => state.policy.create)
    const attachStatementsPolicySelector = useAppSelector(state => state.policy.attachStatements)

    const [createPolicyForm, setCreatePolicyForm] = useState<CreateDto>(createPolicyInitialState)
    const [createPolicyFormErrors, setCreatePolicyFormErrors] = useState<CreateDto>(createPolicyInitialState)

    const [statementsForm, setStatementsForm] = useState(statementsInitialState)
    const [statementsFormErrors, setStatementsFormErrors] = useState(statementsErrorsInitialState)

    useEffect(() => {
        if (createPolicySelector.done && !!!createPolicySelector.error) {
            if (!createPolicySelector.id) return

            dispatch(
                attachStatementsAction({
                    id: createPolicySelector.id,
                    statementIds: statementsForm.statementIds
                })
            )
        }
    }, [createPolicySelector.done])

    useEffect(() => {
        if (attachStatementsPolicySelector.done) {
            props.onCompleted(createPolicySelector.done)
            dispatch(resetCreateAction())
        }
    }, [attachStatementsPolicySelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreatePolicyForm({
            ...createPolicyForm,
            [event.target.name]: event.target.value
        })
    }

    const handleStatemetsInputChange = (name: string, value: string[]) => {
        setStatementsForm({
            ...statementsForm,
            [name]: value
        })
    }

    const handleCreatePolicyFormSubmit = () => {
        const formValidation = validateForm(schema, createPolicyForm)

        if (formValidation.error) {
            setCreatePolicyFormErrors({
                ...createPolicyInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(createPolicyForm)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={createPolicyForm.name}
                        onChange={handleInputChange}
                        error={Boolean(createPolicyFormErrors.name)}
                        helperText={createPolicyFormErrors.name}
                    />

                    <StatementsCheckBoxGroupInput
                        label="Permisos"
                        name="statementIds"
                        value={statementsForm.statementIds}
                        error={statementsFormErrors.statementIds}
                        onChange={handleStatemetsInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createPolicySelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createPolicySelector.loading}
                    variant="contained"
                    onClick={handleCreatePolicyFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
