import { SelectChangeEvent } from '@mui/material/Select';
import SelectInput from 'app/components/SelectInput';
import { ContractStatus } from '../../contract.entity';

type Props = {
    label: string
    name: string
    value?: string
    error?: string
    onChange?: (event: SelectChangeEvent) => void
}

type Option = {
    label: string
    value: string
}

const options: Option[] = [
    {
        label: 'En revisión',
        value: ContractStatus.REVIEW
    },
    {
        label: 'Suspendido temporalmente',
        value: ContractStatus.ON_HOLD
    },
    {
        label: 'Habilitado',
        value: ContractStatus.ENABLED
    },
    {
        label: 'Finalizado',
        value: ContractStatus.FINISHED
    },
    {
        label: 'Cancelado',
        value: ContractStatus.CANCELLED
    },
    {
        label: 'Deshabilitado',
        value: ContractStatus.DISABLED
    },
]

export default function ContractStatusSelectInput(props: Props) {
    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={props.label}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={props.onChange}
        />
    )
}