import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { ProfileService } from './profile.service'
import { UpdateInfoDto } from './pages/UpdateInfo/update-info.dto'
import { UpdatePasswordDto } from './pages/UpdatePassword/update-password.dto'
import { handleApiResponses } from 'app/redux/helpers/async-thunk.helper'

const sliceName = 'profile'

const initialActionState = {
    loading: false,
    error: '',
    done: false
}

const initialProfileInfo: UpdateInfoDto = {
    name: '',
    lastName: '',
    email: '',
}

const initialUpdatePassword: UpdatePasswordDto = {
    lastPassword: '',
    newPassword: ''
}

const initialProfileInfoState = {
    ...initialActionState,
    data: initialProfileInfo
}

const initialUpdatePasswordState = {
    ...initialActionState,
    data: initialUpdatePassword
}

const initialState = {
    profileInfo: initialProfileInfoState,
    updatePassword: initialUpdatePasswordState,
}

const getProfileAction = createAsyncThunk(
    `${sliceName}/get`,
    async () => {
        const service = new ProfileService()

        return service.getMyProfile()
    }
)

const updateInfoAction = createAsyncThunk(
    `${sliceName}/updateInfo`,
    async (input: UpdateInfoDto) => {
        const service = new ProfileService()

        return service.updateInfo(input)
    }
)

const updatePasswordAction = createAsyncThunk(
    `${sliceName}/updatePassword`,
    async (input: UpdatePasswordDto, thunkApi) => {
        const service = new ProfileService()
        const response = await service.updatePassword(input)

        return handleApiResponses(response, thunkApi)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetUpdateInfoAction: (state) => {
            state.profileInfo = initialProfileInfoState
        },
        resetUpdatePasswordAction: (state) => {
            state.updatePassword = initialUpdatePasswordState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileAction.pending, (state) => {
                state.profileInfo.loading = true;
                state.profileInfo.error = ''
                state.profileInfo.done = false
                state.profileInfo.data = initialProfileInfo
            })
            .addCase(getProfileAction.fulfilled, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = ''
                state.profileInfo.done = true
                state.profileInfo.data = action.payload as UpdateInfoDto
            })
            .addCase(getProfileAction.rejected, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = action.error.message || 'Someting wrong occured'
                state.profileInfo.done = false
                state.profileInfo.data = initialProfileInfo
            })

            .addCase(updateInfoAction.pending, (state) => {
                state.profileInfo.loading = true;
                state.profileInfo.error = ''
                state.profileInfo.done = false
            })
            .addCase(updateInfoAction.fulfilled, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = ''
                state.profileInfo.done = action.payload
            })
            .addCase(updateInfoAction.rejected, (state, action) => {
                state.profileInfo.loading = false;
                state.profileInfo.error = action.error.message || 'Someting wrong occured'
                state.profileInfo.done = false
            })

            .addCase(updatePasswordAction.pending, (state) => {
                state.updatePassword.loading = true
                state.updatePassword.error = initialState.updatePassword.error
                state.updatePassword.done = initialState.updatePassword.done
            })
            .addCase(updatePasswordAction.fulfilled, (state, action) => {
                state.updatePassword.loading = initialState.updatePassword.loading
                state.updatePassword.error = initialState.updatePassword.error
                state.updatePassword.done = true
            })
            .addCase(updatePasswordAction.rejected, (state, action) => {
                state.updatePassword.loading = false
                state.updatePassword.error = action.payload as string
                state.updatePassword.done = false
            })
    }
})

export {
    getProfileAction,
    updateInfoAction,
    updatePasswordAction
}

export const { resetUpdateInfoAction, resetUpdatePasswordAction } = slice.actions
export const selector = (state: RootState) => state.profile
export default slice.reducer;
