import { JoinDefinition } from "app/types/join.type";
import { EntityAttribute, EntityName } from "../../interfaces/EntityInterface";
import { EntitySchema as RoleEntitySchema } from '../role/role.entity'
import { JoinAndCountDefinition } from "app/types/join-and-count.type";
import { BaseUiEntity } from "app/classes/base-ui-entity";

export type EntitySchema = {
    id: string
    name: string
    lastName: string
    email: string
    password: string
    createdAt: string
    updatedAt: string
    roles: RoleEntitySchema[]
}

export const entityInitialState = {
    id: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    createdAt: '',
    updatedAt: '',
    roles: []
}

const name: EntityName = {
    singular: 'Usuario',
    plural: 'Usuarios'
}

const slug = 'user-backoffice'

const primaryKeyAttribute: string = 'id'

const linkedToViewAttribute: string = 'name'

const attributes: EntityAttribute[] = [
    {
        key: 'id',
        label: 'ID',
        dataType: 'string',
        showInTable: false,
        // order: 0
    },
    {
        key: 'name',
        label: 'Nombre',
        dataType: 'string',
        showInTable: true,
        // order: 1
    },
    {
        key: 'lastName',
        label: 'Apellido',
        dataType: 'string',
        showInTable: true,
        // order: 2
    },
    {
        key: 'email',
        label: 'Correo electrónico',
        dataType: 'string',
        showInTable: true,
        // order: 2
    },
    {
        key: 'createdAt',
        label: 'Creado',
        dataType: 'date',
        showInTable: true,
        // order: 3
    },
    {
        key: 'updatedAt',
        label: 'Actualizado',
        dataType: 'date',
        showInTable: false,
        // order: 4
    },
]

const join: JoinDefinition[] = []

const joinAndCount: JoinAndCountDefinition[] = []

export class Entity extends BaseUiEntity {
    constructor() {
        super(
            name,
            slug,
            primaryKeyAttribute,
            linkedToViewAttribute,
            attributes,
            join,
            joinAndCount,
        )
    }
}
