import { useMemo, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList, { MenuListItem } from "./MenuList";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useLocation, useNavigate } from "react-router-dom";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

type Props = {
    index: number
    label: string
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    children: MenuListItem[]
    level: number
    link: string
}

export default function MenuItem(props: Props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false);

    const isSelected = useMemo(() => {
        if (props.children?.length > 0) {
            return props.children.some(child => child.link === location.pathname)
        } else {
            return location.pathname === props.link
        }

    }, [location])

    const isExpanded = useMemo(() => {
        return Boolean(open || isSelected)
    }, [open, isSelected])

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()

        if (props.children?.length > 0) {
            setOpen(!open)
        } else {
            if (props.link) navigate(props.link)
        }
    };

    const paddingLeft = props.level > 0 ? props.level + 4 : 2

    return (
        <>
            <ListItemButton
                key={`menuItem${props.index}`}
                selected={isSelected}
                href={props.link || '#'}
                sx={{ paddingLeft: paddingLeft }}
                onClick={handleClick}
            >
                <ListItemIcon>
                    {<props.icon />}
                </ListItemIcon>
                <ListItemText primary={props.label} />
                {
                    (props.children?.length) ?
                        (
                            (isExpanded) ?
                                <ExpandLess /> : <ExpandMore />
                        ) : <></>
                }
            </ListItemButton>

            {
                (props.children?.length) ?
                    (<Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <MenuList items={props.children} level={props.level + 1} />
                    </Collapse>) : <> </>
            }
        </>
    )
}
