import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateInfoAction, resetUpdateInfoAction, findOneAction, attachRbacRolesAction, resetAttachRbacRolesAction } from '../../user-backoffice.slice'
import { UpdateInfoDto } from './update-info.dto'
import { Entity } from "../../user-backoffice.entity";
import RolesCheckBoxGroupInput from "app/modules/role/components/RolesCheckBoxGroupInput";
import { RoleScopesEnum } from "app/modules/role/role.enums";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState = {
    name: '',
    lastName: '',
    email: ''
}

const errorsInitialState = formInitialState
const uiEntity = new Entity()

const rbacRolesInitialState = {
    roleIds: [] as string[]
}

const rbacRolesErrorsInitialState = {
    roleIds: ''
}

export default function UpdateInfo(props: Props) {
    const dispatch = useAppDispatch()
    const getUserSelector = useAppSelector(state => state.userBackoffice.view)
    const updateInfoSelector = useAppSelector(state => state.userBackoffice.updateInfo)
    const attachRbacRolesSelector = useAppSelector(state => state.userBackoffice.attachRbacRoles)

    const [form, setForm] = useState<UpdateInfoDto>(formInitialState)
    const [formErrors, setFormErrors] = useState<UpdateInfoDto>(errorsInitialState)

    const [rbacRolesForm, setRbacRolesForm] = useState(rbacRolesInitialState)
    const [rbacRolesFormErrors, setRbacRolesFormErrors] = useState(rbacRolesErrorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id,
            join: 'roles'
        }))
    }, [])

    useEffect(() => {
        if (!getUserSelector.data) return

        setForm({
            name: getUserSelector.data.name,
            lastName: getUserSelector.data.lastName,
            email: getUserSelector.data.email
        })

        setRbacRolesForm({
            roleIds: getUserSelector.data.roles.map(role => role.id!)
        })
    }, [getUserSelector.data])

    useEffect(() => {
        if (updateInfoSelector.done && !!!updateInfoSelector.error) {
            dispatch(
                attachRbacRolesAction({
                    id: props.id,
                    roleIds: rbacRolesForm.roleIds
                })
            )
        }
    }, [updateInfoSelector.done])

    useEffect(() => {
        if (attachRbacRolesSelector.done && !!!attachRbacRolesSelector.error) {
            dispatch(resetUpdateInfoAction())
            dispatch(resetAttachRbacRolesAction())

            props.onCompleted(updateInfoSelector.done)
        }
    }, [attachRbacRolesSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleRolesInputChange = (name: string, value: string[]) => {
        setRbacRolesForm({
            ...rbacRolesForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: form.name,
            lastName: form.lastName,
            email: form.email
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            setFormErrors({
                ...errorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateInfoAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(formErrors.name)}
                        helperText={formErrors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="lastName"
                        label="Apellido"
                        value={form.lastName}
                        onChange={handleInputChange}
                        error={Boolean(formErrors.lastName)}
                        helperText={formErrors.lastName}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        value={form.email}
                        onChange={handleInputChange}
                        error={Boolean(formErrors.email)}
                        helperText={formErrors.email}
                    />

                    <RolesCheckBoxGroupInput
                        label="Roles"
                        name="roleIds"
                        scope={RoleScopesEnum.BACK_OFFICE}
                        value={rbacRolesForm.roleIds}
                        error={rbacRolesFormErrors.roleIds}
                        onChange={handleRolesInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateInfoSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateInfoSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
