import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { findOneAction, updateAction, attachTestVersionsAction, resetAttachTestVersionsAction, resetUpdateAction, resetViewAction } from '../../plan.slice'
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";

import { schema } from "./schema";
import { UpdateDto } from './update.dto'
import { planFeature } from "../../plan.feature";
import { EntitySchema } from "../../plan.entity";
import { getMatchingProperties } from "app/helpers/object.helper";
import PlanStatusSelectInput from "../../components/PlanStatusSelectInput";
import { SelectChangeEvent } from "@mui/material/Select";
import TestVersionCheckBoxGroupInput from "app/modules/test-version/components/TestVersionCheckBoxGroupInput";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (updated: boolean, data?: EntitySchema) => void
}

const initialState: UpdateDto = {
    name: '',
    status: '',
    maxEmployeesNum: 0,
    durationInDays: 0,
    price: 0
}

const initialErrorState = {
    name: '',
    status: '',
    maxEmployeesNum: '',
    durationInDays: '',
    price: ''
}

const testVersionInitialState = {
    testVersionIds: [] as string[]
}

const testVersionErrorsInitialState = {
    testVersionIds: ''
}

const feature = planFeature

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const viewSelector = useAppSelector(state => state.plan.view)
    const updateSelector = useAppSelector(state => state.plan.update)
    const attachTestVersionsSelector = useAppSelector(state => state.plan.attachTestVersions)

    const [form, setForm] = useState<UpdateDto>(initialState)
    const [errors, setErrors] = useState(initialErrorState)

    const [testVersionForm, setTestVersionForm] = useState(testVersionInitialState)
    const [testVersionFormErrors, setTestVersionFormErrors] = useState(testVersionErrorsInitialState)

    useEffect(() => {
        dispatch(resetViewAction())
        dispatch(resetUpdateAction())
        dispatch(resetAttachTestVersionsAction())

        dispatch(findOneAction({
            id: props.id,
            join: 'testVersions'
        }))
    }, [])

    useEffect(() => {
        if (updateSelector.done && !!!updateSelector.error) {
            dispatch(
                attachTestVersionsAction({
                    id: props.id,
                    testVersionIds: testVersionForm.testVersionIds
                })
            )
        }
    }, [updateSelector.done])

    useEffect(() => {
        if (attachTestVersionsSelector.done && !!!attachTestVersionsSelector.error) {
            dispatch(resetUpdateAction())
            dispatch(resetAttachTestVersionsAction())

            props.onCompleted(attachTestVersionsSelector.done)
        }
    }, [attachTestVersionsSelector.done])

    useEffect(() => {
        if (viewSelector.done && viewSelector.data) {
            setForm(
                getMatchingProperties(initialState, viewSelector.data)
            )

            if (viewSelector.data.testVersions && viewSelector.data.testVersions.length) {
                setTestVersionForm({
                    testVersionIds: viewSelector.data.testVersions.map(testVersion => testVersion.id!) || []
                })
            }
        }
    }, [viewSelector])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleTestVersionInputChange = (name: string, value: string[]) => {
        setTestVersionForm({
            ...testVersionForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                updateAction({
                    id: props.id,
                    dto: form
                })
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="durationInDays"
                        label="Duración en días"
                        value={form.durationInDays}
                        onChange={handleInputChange}
                        error={Boolean(errors.durationInDays)}
                        helperText={errors.durationInDays}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="maxEmployeesNum"
                        label="Número máximo de colaboradores"
                        value={form.maxEmployeesNum}
                        onChange={handleInputChange}
                        error={Boolean(errors.maxEmployeesNum)}
                        helperText={errors.maxEmployeesNum}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="price"
                        label="Valor en COP"
                        value={form.price}
                        onChange={handleInputChange}
                        error={Boolean(errors.price)}
                        helperText={errors.price}
                    />

                    <PlanStatusSelectInput
                        label="Estado del plan"
                        name="status"
                        value={form.status}
                        error={errors.status}
                        onChange={handleSelectChange}
                    />

                    <TestVersionCheckBoxGroupInput
                        label={testVersionFeature.label.plural}
                        name="testVersionIds"
                        value={testVersionForm.testVersionIds}
                        onChange={handleTestVersionInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
