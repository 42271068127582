import Joi from "joi";
import { UpdateDto } from "./update.dto";
import { PlanStatus } from "../../plan.entity";

export const schema = Joi.object<UpdateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    status: Joi
        .string()
        .valid(PlanStatus.ENABLED)
        .valid(PlanStatus.DISABLED)
        .required(),
    maxEmployeesNum: Joi
        .number()
        .min(0)
        .required(),
    durationInDays: Joi
        .number()
        .min(0)
        .required(),
    price: Joi
        .number()
        .min(0)
        .required(),
})
