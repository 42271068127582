import Joi from "joi";
import { CreateDto } from "./create.dto";

export const schema = Joi.object<CreateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    lastName: Joi
        .string()
        .label('Apellido')
        .min(3)
        .max(255)
        .required(),
    email: Joi
        .string()
        .label('Correo electrónico')
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi
        .string()
        .label('Contraseña')
        .required()
})
