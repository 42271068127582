import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, resetUpdateAction, findOneAction } from '../../test-version.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../test-version.entity";
import TestSelectInput from "app/modules/test/components/TestSelectInput";
import { testFeature } from "app/modules/test/test.feature";
import { SelectChangeEvent } from "@mui/material/Select";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const updateTestInitialState = {
    name: '',
    description: '',
    uiUrl: '',
    testId: ''
}

const updateTestErrorsInitialState = {
    name: '',
    description: '',
    uiUrl: '',
    testId: ''
}

const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const getTestSelector = useAppSelector(state => state.testVersion.view)
    const updateTestSelector = useAppSelector(state => state.testVersion.update)

    const [updateTestForm, setUpdateTestForm] = useState<UpdateDto>(updateTestInitialState)
    const [updateTestFormErrors, setUpdateTestFormErrors] = useState<UpdateDto>(updateTestErrorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id
        }))
    }, [])

    useEffect(() => {
        if (!getTestSelector.data) return

        setUpdateTestForm({
            name: getTestSelector.data.name,
            description: getTestSelector.data.description,
            uiUrl: getTestSelector.data.uiUrl,
            testId: getTestSelector.data.testId
        })
    }, [getTestSelector.data])

    useEffect(() => {
        if (updateTestSelector.done && !!!updateTestSelector.error) {
            dispatch(resetUpdateAction())
            props.onCompleted(updateTestSelector.done)
        }
    }, [updateTestSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateTestForm({
            ...updateTestForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setUpdateTestForm({
            ...updateTestForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formData = {
            name: updateTestForm.name,
            description: updateTestForm.description,
            uiUrl: updateTestForm.uiUrl,
            testId: updateTestForm.testId,
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            setUpdateTestFormErrors({
                ...updateTestErrorsInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={updateTestForm.name ? updateTestForm.name : ''}
                        onChange={handleInputChange}
                        error={Boolean(updateTestFormErrors.name)}
                        helperText={updateTestFormErrors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="description"
                        label="Descripción"
                        value={updateTestForm.description}
                        onChange={handleInputChange}
                        error={Boolean(updateTestFormErrors.description)}
                        helperText={updateTestFormErrors.description}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="uiUrl"
                        label="Url para resolver la prueba"
                        value={updateTestForm.uiUrl}
                        onChange={handleInputChange}
                        error={Boolean(updateTestFormErrors.uiUrl)}
                        helperText={updateTestFormErrors.uiUrl}
                    />

                    <TestSelectInput
                        label={testFeature.label.singular}
                        name="testId"
                        value={updateTestForm.testId}
                        onChange={handleSelectChange}
                        error={updateTestFormErrors.testId}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateTestSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateTestSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
