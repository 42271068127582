import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createFromPlanAction, resetCreateFromPlanAction } from '../../contract.slice'
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";
import { schema } from "./schema";
import { CreateFromPlanDto } from './create-from-plan.dto'
import { contractFeature } from "../../contract.feature";
import { ContractStatus } from "../../contract.entity";
import ContractStatusSelectInput from "../../components/ContractStatusSelectInput";
import { SelectChangeEvent } from "@mui/material/Select";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";
import PlanAutoCompleteInput from "app/modules/plan/components/PlanAutoCompleteInput";
import PlanSelectInput from "app/modules/plan/components/PlanSelectInput";

type Props = {
    onCancelled: () => void
    onCompleted: (id?: string) => void
}

const initialState: CreateFromPlanDto = {
    planId: '',
    organizationId: ''
}

const initialErrorState = {
    planId: '',
    organizationId: ''
}

const feature = contractFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createSelector = useAppSelector(state => state.contract.createFromPlan)

    const [form, setForm] = useState<CreateFromPlanDto>(initialState)
    const [errors, setErrors] = useState(initialErrorState)

    useEffect(() => {
        if (createSelector.done)
            props.onCompleted(createSelector.id)

        dispatch(resetCreateFromPlanAction())
    }, [createSelector.done])

    const handlePlanSelect = (name: string, value?: string) => {
        setForm({
            ...form,
            planId: value || ''
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleOrganizationSelect = (name: string, value?: string) => {
        setForm({
            ...form,
            organizationId: value || ''
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createFromPlanAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <PlanSelectInput
                        name="planId"
                        value={form.planId}
                        onChange={handleSelectChange}
                        error={errors.planId}
                    />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={form.organizationId}
                        onSelect={handleOrganizationSelect}
                        error={errors.organizationId}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
