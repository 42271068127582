import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { roleFeature } from "../../role.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction, attachPoliciesAction } from '../../role.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import { SelectChangeEvent } from "@mui/material/Select";
import PolicyCheckBoxGroupInput from "app/modules/policy/components/PolicyCheckBoxGroupInput";
import SelectInput from "app/components/SelectInput";
import { roleScopes } from "../../role.constants";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const roleInitialState: CreateDto = {
    name: '',
    scope: ''
}

const policyInitialState = {
    policyIds: []
}

const policyErrorsInitialState = {
    policyIds: ''
}

const feature = roleFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createRoleSelector = useAppSelector(state => state.role.create)
    const attachRolesPolicySelector = useAppSelector(state => state.role.attachPolicies)

    const [createRoleForm, setCreateRoleForm] = useState<CreateDto>(roleInitialState)
    const [createRoleFormErrors, setCreateRoleFormErrors] = useState<CreateDto>(roleInitialState)

    const [policyForm, setPolicyForm] = useState(policyInitialState)
    const [policyFormErrors, setPolicyFormErrors] = useState(policyErrorsInitialState)

    useEffect(() => {
        if (createRoleSelector.done && !!!createRoleSelector.error) {
            if (!createRoleSelector.id) return

            dispatch(
                attachPoliciesAction({
                    id: createRoleSelector.id,
                    policyIds: policyForm.policyIds
                })
            )
        }
    }, [createRoleSelector.done])

    useEffect(() => {
        if (attachRolesPolicySelector.done) {
            props.onCompleted(createRoleSelector.done)
            dispatch(resetCreateAction())
        }
    }, [attachRolesPolicySelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateRoleForm({
            ...createRoleForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setCreateRoleForm({
            ...createRoleForm,
            [event.target.name]: event.target.value
        })
    }

    const handlePolicyInputChange = (name: string, value: string[]) => {
        setPolicyForm({
            ...policyForm,
            [name]: value
        })
    }

    const handleCreateRoleFormSubmit = () => {
        const formValidation = validateForm(schema, createRoleForm)

        if (formValidation.error) {
            setCreateRoleFormErrors({
                ...roleInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(createRoleForm)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={createRoleForm.name}
                        onChange={handleInputChange}
                        error={Boolean(createRoleFormErrors.name)}
                        helperText={createRoleFormErrors.name}
                    />

                    <SelectInput
                        fullWidth
                        name="scope"
                        label="Ámbito"
                        options={roleScopes}
                        value={createRoleForm.scope}
                        error={createRoleFormErrors.scope}
                        onChange={handleSelectChange}
                    />

                    <PolicyCheckBoxGroupInput
                        label="Políticas"
                        name="policyIds"
                        value={policyForm.policyIds}
                        error={policyFormErrors.policyIds}
                        onChange={handlePolicyInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createRoleSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createRoleSelector.loading}
                    variant="contained"
                    onClick={handleCreateRoleFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
