import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { testDimensionFeature as testDimensionFeature } from "../../test-dimension.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction } from '../../test-dimension.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import { SelectChangeEvent } from "@mui/material";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";
import { useQuery } from "app/hooks";
import TestVersionSelectInput from "app/modules/test-version/components/TestVersionSelectInput";
import TestDimensionSelectInput from "../../components/TestDimensionSelectInput";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const initialState: CreateDto = {
    name: '',
    description: '',
    testVersionId: '',
    testDimensionId: undefined
}

const feature = testDimensionFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const query = useQuery()
    const createSelector = useAppSelector(state => state.testDimension.create)

    const [form, setForm] = useState<CreateDto>({
        ...initialState,
        testVersionId: query.get('testVersionId') || '',
    })
    const [formError, setFormError] = useState<CreateDto>(initialState)

    useEffect(() => {
        if (createSelector.done && Boolean(createSelector.error) === false) {
            props.onCompleted(createSelector.done)
            dispatch(resetCreateAction())
        }
    }, [createSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleFormSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setFormError({
                ...initialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(formError.name)}
                        helperText={formError.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="description"
                        label="Descripción"
                        multiline
                        rows={3}
                        value={form.description}
                        onChange={handleInputChange}
                        error={Boolean(formError.description)}
                        helperText={formError.description}
                    />

                    <TestVersionSelectInput
                        label={testVersionFeature.label.singular}
                        name="testVersionId"
                        value={form.testVersionId}
                        onChange={handleSelectChange}
                        error={formError.testVersionId}
                    />

                    {
                        form.testVersionId && (
                            <TestDimensionSelectInput
                                label={testDimensionFeature.label.singular}
                                name="testDimensionId"
                                testVersionId={form.testVersionId}
                                value={form.testDimensionId}
                                onChange={handleSelectChange}
                                error={formError.testDimensionId}
                            />
                        )
                    }
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
