import { Navigate, Outlet } from 'react-router-dom';
import { AuthContainer } from './styled'
import { StorageService } from '../../../services/StorageService';

export default function AuthLayout() {
    const accessToken = StorageService.getAccessToken()

    return accessToken ? 
        <Navigate to="/backoffice" /> : 
        <AuthContainer><Outlet /></AuthContainer>
}
