import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { assessmentFeature as assessmentFeature } from "../../assessment.feature";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction } from '../../assessment.slice';
import { CreateDto } from './create.dto';
import { schema } from "./schema";
import TestSelectInput from "app/modules/test/components/TestSelectInput";
import { testFeature } from "app/modules/test/test.feature";
import { SelectChangeEvent } from "@mui/material";
import OrganizationAutoCompleteInput from "app/modules/organization/components/OrganizationAutoCompleteInput";
import TestVersionSelectInput from "app/modules/test-version/components/TestVersionSelectInput";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const asessmentInitialState: CreateDto = {
    name: '',
    organizationId: '',
    testVersionId: ''
}

const feature = assessmentFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createAssessmentSelector = useAppSelector(state => state.assessment.create)

    const [createAssessmentForm, setCreateAsessmentForm] = useState<CreateDto>(asessmentInitialState)
    const [createAsessmentFormErrors, setCreateAsessmentFormErrors] = useState<CreateDto>(asessmentInitialState)

    useEffect(() => {
        if (createAssessmentSelector.done && Boolean(createAssessmentSelector.error) === false) {
            props.onCompleted(createAssessmentSelector.done)
            dispatch(resetCreateAction())
        }
    }, [createAssessmentSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateAsessmentForm({
            ...createAssessmentForm,
            [event.target.name]: event.target.value
        })
    }

    const handleOrganizationAutoCompleteInputSelect = (name: string, value?: string) => {
        setCreateAsessmentForm({
            ...createAssessmentForm,
            [name]: value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setCreateAsessmentForm({
            ...createAssessmentForm,
            [event.target.name]: event.target.value
        })
    }

    const handleFormSubmit = () => {
        const formValidation = validateForm(schema, createAssessmentForm)

        if (formValidation.error) {
            setCreateAsessmentFormErrors({
                ...asessmentInitialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(createAssessmentForm)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={createAssessmentForm.name}
                        onChange={handleInputChange}
                        error={Boolean(createAsessmentFormErrors.name)}
                        helperText={createAsessmentFormErrors.name}
                    />

                    <OrganizationAutoCompleteInput
                        name="organizationId"
                        value={createAssessmentForm.organizationId}
                        error={createAsessmentFormErrors.organizationId}
                        onSelect={handleOrganizationAutoCompleteInputSelect}
                    />

                    <TestVersionSelectInput
                        label={testFeature.label.singular}
                        name="testVersionId"
                        value={createAssessmentForm.testVersionId}
                        onChange={handleSelectChange}
                        error={createAsessmentFormErrors.testVersionId}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createAssessmentSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createAssessmentSelector.loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
