import { Feature } from '../../types/feature.type';
import PersonIcon from '@mui/icons-material/Person';
import { Entity } from './profile.entity';
import { feminineArticle } from '../../features/articles';

const entity = new Entity()

export const profileFeature: Feature = {
    label: {
        singular: entity.name.singular,
        plural: entity.name.plural,
    },
    menu: {
        grammaticalNumber: 'singular'
    },
    article: feminineArticle,
    icon: PersonIcon,
    isAuthorized: true,
    entryLink: `/backoffice/${entity.slug}`,
}
