import { useEffect, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { findAllAction } from '../../organizational-unit.slice';
import Create from '../Create';
import DataTable from '../../../../components/DataTable';
import ActionButtons, { ActionButton } from '../../../../components/ActionButtons';
import { AuthorizationService } from '../../../../services/AuthorizationService';
import Update from '../Update';
import PageHeader from '../../../../components/PageHeader';
import { Entity, EntitySchema } from '../../organizational-unit.entity';
import { organizationalUnitFeature } from '../../organizational-unit.feature';
import FormDialog from '../../../../components/FormDialog';
import Delete from '../Delete';
import { JoinDefinition } from '../../../../types/join.type';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { FilterState } from 'app/types/pagination.dto';
import { useQuery } from 'app/hooks';
import { buildFilterByQueryParams } from 'app/helpers/pagination.helper';
import { userEmployeeFeature } from 'app/modules/user-employee/user-employee.feature';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { JoinAndCountDefinition } from 'app/types/join-and-count.type';

const feature = organizationalUnitFeature
const entity = new Entity()

const joinQuery = entity.join.map(entity => {
    return `${entity.entity}:${entity.attributes.join('|')}`
}).join(',')

const joinAndCountQuery = entity.joinAndCount.map(entity => {
    return entity.entity
}).join(',')

const joinInitialState = {
    state: entity.join,
    query: joinQuery
}

const joinAndCountInitialState = {
    state: entity.joinAndCount,
    query: joinAndCountQuery
}

export default function List() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const query = useQuery()

    const filterInitialState: FilterState | undefined = buildFilterByQueryParams([
        {
            id: 'organizationId',
            value: query.get('organizationId')
        },
    ])

    const listSelector = useAppSelector(state => state.organizationalUnit.list)
    const deleteSelector = useAppSelector(state => state.organizationalUnit.delete)
    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<{ state: MRT_SortingState, query?: string }>()
    const [filter, setFilter] = useState<FilterState | undefined>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: listSelector.meta.page,
        pageSize: listSelector.meta.perPage
    })
    const [join] = useState<{ state: JoinDefinition[], query?: string }>(joinInitialState)
    const [joinAndCount] = useState<{ state: JoinAndCountDefinition[], query?: string }>(joinAndCountInitialState)

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [createEnabled, setCreateEnabled] = useState(false)
    const [updateEnabled, setUpdateEnabled] = useState(false)
    const [deleteEnabled, setDeleteEnabled] = useState(false)
    const [viewEmployeesEnabled, setViewEmployeesEnabled] = useState(false)

    const [refreshVisible, setRefreshVisible] = useState(false)
    const [createVisible, setCreateVisible] = useState(false)
    const [updateVisible, setUpdateVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query,
            join: join?.query,
            joinAndCount: joinAndCount?.query
        }))
    }

    const viewEmployeesByOrganizationalUnit = (organizationalUnitId: string) => {
        const params = {
            organizationalUnitId
        }

        navigate({
            pathname: `../${userEmployeeFeature.entryLink}`,
            search: `?${createSearchParams(params)}`
        })
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized('organizational-unit:findAll')

        setRefreshVisible(userIsAuthorizedToFindAll)
        setRefreshEnabled(userIsAuthorizedToFindAll)

        setCreateVisible(AuthorizationService.userIsAuthorized('organizational-unit:create'))
        setUpdateVisible(AuthorizationService.userIsAuthorized('organizational-unit:update'))
        setDeleteVisible(AuthorizationService.userIsAuthorized('organizational-unit:create'))
    }, [])

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done])

    useEffect(() => {
        setCreateEnabled(!selectedRow)
        setUpdateEnabled(!!selectedRow)
        setDeleteEnabled(!!selectedRow)
        setViewEmployeesEnabled(!!selectedRow)
    }, [selectedRow])

    useEffect(() => {
        refreshData()
    }, [sorting, filter, join, page])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleCreateCancelled = () => {
        setCreateModalOpen(false)
    }

    const handleCreateCompleted = (created: boolean) => {
        setCreateModalOpen(false)

        if (created) {
            refreshData()
        }
    }

    const handleUpdateCancelled = () => {
        setUpdateModalOpen(false)
    }

    const handleUpdateCompleted = (updated: boolean) => {
        setUpdateModalOpen(false)

        if (updated) {
            refreshData()
        }
    }

    const handleDeleteCancelled = () => {
        setDeleteModalOpen(false)
    }

    const handleDeleteCompleted = (deleted: boolean) => {
        if (deleted) {
            setDeleteModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Crear',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: userEmployeeFeature.label.plural,
            icon: userEmployeeFeature.icon,
            color: 'primary',
            isEnabled: viewEmployeesEnabled,
            isVisible: userEmployeeFeature.isAuthorized,
            onClick: () => viewEmployeesByOrganizationalUnit(selectedRow!.id)
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateEnabled,
            isVisible: updateVisible,
            onClick: () => setUpdateModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={listSelector}
                sorting={sorting?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            <FormDialog open={createModalOpen}>
                <Create
                    onCancelled={handleCreateCancelled}
                    onCompleted={handleCreateCompleted}
                />
            </FormDialog>

            {
                selectedRow && (
                    <>
                        <FormDialog open={updateModalOpen}>
                            <Update
                                id={selectedRow?.id || ''}
                                onCancelled={handleUpdateCancelled}
                                onCompleted={handleUpdateCompleted}
                            />
                        </FormDialog>

                        <FormDialog open={deleteModalOpen}>
                            <Delete
                                id={selectedRow?.id || ''}
                                onCancelled={handleDeleteCancelled}
                                onCompleted={handleDeleteCompleted}
                            />
                        </FormDialog>
                    </>
                )
            }
        </>
    );
}
