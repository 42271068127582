import { EntityAttribute, EntityName, UiEntity } from "../../interfaces/EntityInterface"
import { JoinDefinition } from "../../types/join.type"
import { EntitySchema as TestVersionEntitySchema } from '../test-version/test-version.entity'

export enum PlanStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export type EntitySchema = {
    id: string
    name: string
    status: string
    maxEmployeesNum: number
    durationInDays: number
    price: number
    testVersions: TestVersionEntitySchema[]
    createdAt: string
    updatedAt: string
}

export const entityInitialState: EntitySchema = {
    id: '',
    name: '',
    status: '',
    maxEmployeesNum: 0,
    durationInDays: 0,
    price: 0,
    testVersions: [],
    createdAt: '',
    updatedAt: '',
}

export class PlanEntity implements UiEntity {
    name: EntityName = {
        singular: 'Plan',
        plural: 'Planes'
    }

    slug = 'plan'

    primaryKeyAttribute: string = 'id'

    linkedToViewAttribute: string = 'name'

    attributes: EntityAttribute[] = [
        {
            key: 'id',
            label: 'ID',
            dataType: 'string',
            showInTable: false,
        },
        {
            key: 'name',
            label: 'Nombre',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'durationInDays',
            label: 'Duración en días',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'maxEmployeesNum',
            label: 'Número máximo de colaboradores',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'price',
            label: 'Valor en COP',
            dataType: 'currency',
            showInTable: true,
        },
        {
            key: 'status',
            label: 'Estado del plan',
            dataType: 'string',
            showInTable: true,
        },
        {
            key: 'createdAt',
            label: 'Creado',
            dataType: 'date',
            showInTable: true,
            filter: false
        },
    ]

    join: JoinDefinition[] = []
}
