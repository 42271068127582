import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { PlanService } from './test-application-record.service'
import { EntitySchema } from './test-application-record.entity'
import { ListDto } from './pages/List/list.dto'
import { CreateForEmployeeDto } from './pages/CreateForEmployees/create-for-employee.dto'
import { DeleteDto } from './pages/Delete/delete.dto'
import { UpdateDto } from './pages/Update/update.dto'
import { initialActionState, initialCreateState, initialDeleteState, initialListState, initialUpdateState, initialViewState } from 'app/redux/helpers/state.helper'
import { CreateForOrganizationalUnitDto } from './pages/CreateForOrganizationalUnit/create-for-organizational-unit.dto'
import { CreateForOrganizationDto } from './pages/CreateForOrganization/create-for-organization.dto'

const sliceName = 'test-application-record'

const initialAutocompleteState = {
    ...initialActionState,
    data: [] as EntitySchema[]
}

const initialState = {
    list: initialListState<EntitySchema>(),
    view: initialViewState<EntitySchema>(),
    createForEmployee: initialCreateState(),
    createForOrganization: initialActionState,
    createForOrganizationalUnit: initialActionState,
    update: initialUpdateState(),
    delete: initialDeleteState(),
    autocomplete: initialAutocompleteState
}

const findOneAction = createAsyncThunk(
    `${sliceName}/findOne`,
    async (id: string) => {
        const service = new PlanService()

        return service.findOne(id)
    }
)

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    (input: ListDto) => {
        const service = new PlanService()

        return service.findAll(input)
    }
)

const createForOrganizationAction = createAsyncThunk(
    `${sliceName}/createForOrganization`,
    (input: CreateForOrganizationDto) => {
        const service = new PlanService()

        return service.createForOrganization(input)
    }
)

const createForOrganizationalUnitAction = createAsyncThunk(
    `${sliceName}/createForOrganizationalUnit`,
    (input: CreateForOrganizationalUnitDto) => {
        const service = new PlanService()

        return service.createForOrganizationalUnit(input)
    }
)

const createForEmployeeAction = createAsyncThunk(
    `${sliceName}/createForEmployee`,
    (input: CreateForEmployeeDto) => {
        const service = new PlanService()

        return service.createForEmployee(input)
    }
)

const updateAction = createAsyncThunk(
    `${sliceName}/update`,
    (input: { id: string, dto: UpdateDto }) => {
        const { id, dto } = input
        const service = new PlanService()

        return service.update(id, dto)
    }
)

const deleteAction = createAsyncThunk(
    `${sliceName}/delete`,
    async (input: DeleteDto, thunkAPI) => {
        const service = new PlanService()

        const result = await service.delete(input)

        return result.ok ?
            thunkAPI.fulfillWithValue(result.message) :
            thunkAPI.rejectWithValue(result.message)
    }
)

const autocompleteAction = createAsyncThunk(
    `${sliceName}/autocomplete`,
    async (input: ListDto) => {
        const service = new PlanService()

        return service.findAll(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetListAction: (state) => {
            state.list = initialState.list
        },
        resetViewAction: (state) => {
            state.view = initialState.view
        },
        resetCreateForOrganizationAction: (state) => {
            state.createForOrganization = initialState.createForOrganization
        },
        resetCreateForOrganizationalUnitAction: (state) => {
            state.createForOrganizationalUnit = initialState.createForOrganizationalUnit
        },
        resetCreateForEmployeeAction: (state) => {
            state.createForEmployee = initialState.createForEmployee
        },
        resetUpdateAction: (state) => {
            state.update = initialState.update
        },
        resetDeleteAction: (state) => {
            state.delete = initialState.delete
        },
        resetAutocompleteAction: (state) => {
            state.autocomplete = initialState.autocomplete
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.done = false
                state.list.error = initialState.list.error
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.done = true
                state.list.error = initialState.list.error
                state.list.data = action.payload.data
                state.list.meta = action.payload.meta
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.done = false
                state.list.error = action.error.message || 'Someting wrong occured'
                state.list.data = initialState.list.data
                state.list.meta = initialState.list.meta
            })

            .addCase(findOneAction.pending, (state) => {
                state.view.loading = true;
                state.view.done = false
                state.view.error = initialState.view.error
                state.view.data = initialState.view.data
            })
            .addCase(findOneAction.fulfilled, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = initialState.view.error
                state.view.data = action.payload
            })
            .addCase(findOneAction.rejected, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = action.error.message || 'Someting wrong occured'
                state.view.data = initialState.view.data
            })

            .addCase(createForOrganizationAction.pending, (state) => {
                state.createForOrganization.loading = true;
                state.createForOrganization.done = false
                state.createForOrganization.error = initialState.createForOrganization.error
            })
            .addCase(createForOrganizationAction.fulfilled, (state, action) => {
                state.createForOrganization.loading = false;
                state.createForOrganization.done = true
                state.createForOrganization.error = initialState.createForOrganization.error
            })
            .addCase(createForOrganizationAction.rejected, (state, action) => {
                state.createForOrganization.loading = false;
                state.createForOrganization.done = false
                state.createForOrganization.error = action.error.message || 'Someting wrong occured'
            })

            .addCase(createForOrganizationalUnitAction.pending, (state) => {
                state.createForOrganizationalUnit.loading = true;
                state.createForOrganizationalUnit.done = false
                state.createForOrganizationalUnit.error = initialState.createForOrganizationalUnit.error
            })
            .addCase(createForOrganizationalUnitAction.fulfilled, (state, action) => {
                state.createForOrganizationalUnit.loading = false;
                state.createForOrganizationalUnit.done = true
                state.createForOrganizationalUnit.error = initialState.createForOrganizationalUnit.error
            })
            .addCase(createForOrganizationalUnitAction.rejected, (state, action) => {
                state.createForOrganizationalUnit.loading = false;
                state.createForOrganizationalUnit.done = false
                state.createForOrganizationalUnit.error = action.error.message || 'Someting wrong occured'
            })

            .addCase(createForEmployeeAction.pending, (state) => {
                state.createForEmployee.loading = true;
                state.createForEmployee.done = false
                state.createForEmployee.error = initialState.createForEmployee.error
                state.createForEmployee.id = initialState.createForEmployee.id
            })
            .addCase(createForEmployeeAction.fulfilled, (state, action) => {
                state.createForEmployee.loading = false;
                state.createForEmployee.done = true
                state.createForEmployee.error = initialState.createForEmployee.error
                state.createForEmployee.id = action.payload
            })
            .addCase(createForEmployeeAction.rejected, (state, action) => {
                state.createForEmployee.loading = false;
                state.createForEmployee.done = false
                state.createForEmployee.error = action.error.message || 'Someting wrong occured'
                state.createForEmployee.id = initialState.createForEmployee.id
            })

            .addCase(updateAction.pending, (state) => {
                state.update.loading = true;
                state.update.done = false
                state.update.error = initialState.update.error
            })
            .addCase(updateAction.fulfilled, (state, action) => {
                state.update.loading = false;
                state.update.done = true
                state.update.error = initialState.update.error
            })
            .addCase(updateAction.rejected, (state, action) => {
                state.update.loading = false;
                state.update.done = false
                state.update.error = action.error.message || 'Someting wrong occured'
            })

            .addCase(deleteAction.pending, (state) => {
                state.delete.loading = true;
                state.delete.done = false
                state.delete.error = initialState.delete.error
            })
            .addCase(deleteAction.fulfilled, (state, action) => {
                state.delete.loading = false;
                state.delete.done = true
                state.delete.error = initialState.delete.error
            })
            .addCase(deleteAction.rejected, (state, action) => {
                state.delete.loading = false;
                state.delete.done = false
                state.delete.error = action.payload as string
            })

            .addCase(autocompleteAction.pending, (state) => {
                state.autocomplete.loading = true;
                state.autocomplete.done = false
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = initialState.autocomplete.data
            })
            .addCase(autocompleteAction.fulfilled, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = true
                state.autocomplete.error = initialState.autocomplete.error
                state.autocomplete.data = action.payload.data
            })
            .addCase(autocompleteAction.rejected, (state, action) => {
                state.autocomplete.loading = false;
                state.autocomplete.done = false
                state.autocomplete.error = action.error.message || 'Someting wrong occured'
                state.autocomplete.data = initialState.autocomplete.data
            })
    }
})

export {
    findOneAction,
    findAllAction,
    createForOrganizationAction,
    createForOrganizationalUnitAction,
    createForEmployeeAction,
    updateAction,
    deleteAction,
    autocompleteAction
}

export const { resetListAction, resetViewAction, resetCreateForOrganizationAction, resetCreateForOrganizationalUnitAction, resetCreateForEmployeeAction, resetUpdateAction, resetDeleteAction, resetAutocompleteAction } = slice.actions
export const selector = (state: RootState) => state.testApplicationRecord
export default slice.reducer;
