import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store'
import { UserBackOfficeService } from './user-backoffice.service'
import { EntitySchema } from './user-backoffice.entity'
import { CreateDto } from './pages/Create/create.dto'
import { UpdateInfo, UpdateInfoDto } from './pages/UpdateInfo/update-info.dto'
import { ListDto } from './pages/List/list.dto'
import { DeleteDto } from "./pages/Delete/delete.dto";
import { UpdatePassword, UpdatePasswordDto } from './pages/UpdatePassword/update-password.dto'
import { AttachRbacRolesDto } from './dto/attach-roles.dto'
import { FindOneDto } from './dto/find-one.dto'
import { initialListState, initialViewState, initialCreateState, initialDeleteState } from "app/redux/helpers/state.helper";

const sliceName = 'user-backoffice'

const initialActionState = {
    loading: false,
    error: '',
    done: false
}

const initialMetaState = {
    page: 1,
    perPage: 20,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
}

const initialUpdateInfo: UpdateInfoDto = {
    name: '',
    lastName: '',
    email: ''
}

const initialUpdatePassword: UpdatePasswordDto = {
    password: ''
}

const initialUpdateInfoState = { ...initialActionState, data: initialUpdateInfo }
const initialUpdatePasswordState = { ...initialActionState, data: initialUpdatePassword }
const initialAttachRbacRolesState = initialActionState

export const initialState = {
    create: initialCreateState(),
    list: initialListState<EntitySchema>(),
    view: initialViewState<EntitySchema>(),
    updateInfo: initialUpdateInfoState,
    updatePassword: initialUpdatePasswordState,
    delete: initialDeleteState(),
    attachRbacRoles: initialAttachRbacRolesState
}

const findOneAction = createAsyncThunk(
    `${sliceName}/findOne`,
    async (input: FindOneDto) => {
        const service = new UserBackOfficeService()

        return service.findOne(input)
    }
)

const findAllAction = createAsyncThunk(
    `${sliceName}/findAll`,
    async (input: ListDto) => {
        const service = new UserBackOfficeService()

        return service.findAll(input)
    }
)

const createAction = createAsyncThunk(
    `${sliceName}/create`,
    async (input: CreateDto) => {
        const service = new UserBackOfficeService()

        return service.create(input)
    }
)

const deleteAction = createAsyncThunk(
    `${sliceName}/delete`,
    (input: DeleteDto) => {
        const service = new UserBackOfficeService()

        return service.delete(input)
    }
)

const updateInfoAction = createAsyncThunk(
    `${sliceName}/updateInfo`,
    async (input: UpdateInfo) => {
        const { id, dto } = input
        const service = new UserBackOfficeService()

        return service.updateInfo(id, dto)
    }
)

const updatePasswordAction = createAsyncThunk(
    `${sliceName}/updatePassword`,
    async (input: UpdatePassword) => {
        const { id, dto } = input
        const service = new UserBackOfficeService()

        return service.updatePassword(id, dto)
    }
)

const attachRbacRolesAction = createAsyncThunk(
    `${sliceName}/attachRbacRoles`,
    (input: AttachRbacRolesDto) => {
        const service = new UserBackOfficeService()

        return service.attachRbacRoles(input)
    }
)

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        resetDataTableAction: (state) => {
            state.list = initialListState()
        },
        resetCreateAction: (state) => {
            state.create = initialCreateState()
        },
        resetDeleteAction: (state) => {
            state.delete = initialDeleteState()
        },
        resetUpdateInfoAction: (state) => {
            state.updateInfo = initialUpdateInfoState
        },
        resetUpdatePasswordAction: (state) => {
            state.updatePassword = initialUpdatePasswordState
        },
        resetAttachRbacRolesAction: (state) => {
            state.attachRbacRoles = initialAttachRbacRolesState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAction.pending, (state) => {
                state.create.loading = true;
                state.create.done = false
                state.create.error = initialState.create.error
                state.create.id = initialState.create.id
            })
            .addCase(createAction.fulfilled, (state, action) => {
                state.create.loading = false;
                state.create.done = true
                state.create.error = initialState.create.error
                state.create.id = action.payload
            })
            .addCase(createAction.rejected, (state, action) => {
                state.create.loading = false;
                state.create.done = false
                state.create.error = action.error.message || 'Someting wrong occured'
                state.create.id = initialState.create.id
            })

            .addCase(findAllAction.pending, (state) => {
                state.list.loading = true;
                state.list.error = "";
                state.list.data = []
                state.list.meta = initialMetaState
            })
            .addCase(findAllAction.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.error = "";
                state.list.data = action.payload.data;
                state.list.meta = action.payload.meta;
            })
            .addCase(findAllAction.rejected, (state, action) => {
                state.list.loading = false;
                state.list.error = action.error.message || "Someting wrong occured";
                state.list.data = []
                state.list.meta = initialMetaState
            })

            .addCase(findOneAction.pending, (state) => {
                state.view.loading = true;
                state.view.done = false
                state.view.error = initialState.view.error
                state.view.data = initialState.view.data
            })
            .addCase(findOneAction.fulfilled, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = initialState.view.error
                state.view.data = action.payload
            })
            .addCase(findOneAction.rejected, (state, action) => {
                state.view.loading = false;
                state.view.done = true
                state.view.error = action.error.message || 'Someting wrong occured'
                state.view.data = initialState.view.data
            })

            .addCase(updateInfoAction.pending, (state) => {
                state.updateInfo.loading = true;
                state.updateInfo.error = ''
                state.updateInfo.done = false
            })
            .addCase(updateInfoAction.fulfilled, (state, action) => {
                state.updateInfo.loading = false;
                state.updateInfo.error = ''
                state.updateInfo.done = action.payload
            })
            .addCase(updateInfoAction.rejected, (state, action) => {
                state.updateInfo.loading = false;
                state.updateInfo.error = action.error.message || 'Someting wrong occured'
                state.updateInfo.done = false
            })

            .addCase(updatePasswordAction.pending, (state) => {
                state.updatePassword.loading = true;
                state.updatePassword.error = ''
                state.updatePassword.done = false
            })
            .addCase(updatePasswordAction.fulfilled, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = ''
                state.updatePassword.done = action.payload
            })
            .addCase(updatePasswordAction.rejected, (state, action) => {
                state.updatePassword.loading = false;
                state.updatePassword.error = action.error.message || 'Someting wrong occured'
                state.updatePassword.done = false
            })

            .addCase(deleteAction.pending, (state) => {
                state.delete.loading = true;
                state.delete.error = ''
                state.delete.done = false
            })
            .addCase(deleteAction.fulfilled, (state, action) => {
                state.delete.loading = false;
                state.delete.error = ''
                state.delete.done = action.payload
            })
            .addCase(deleteAction.rejected, (state, action) => {
                state.delete.loading = false;
                state.delete.error = action.error.message || 'Someting wrong occured'
                state.delete.done = false
            })

            .addCase(attachRbacRolesAction.pending, (state) => {
                state.attachRbacRoles.loading = true;
                state.attachRbacRoles.error = ''
                state.attachRbacRoles.done = false
            })
            .addCase(attachRbacRolesAction.fulfilled, (state, action) => {
                state.attachRbacRoles.loading = false;
                state.attachRbacRoles.error = ''
                state.attachRbacRoles.done = action.payload
            })
            .addCase(attachRbacRolesAction.rejected, (state, action) => {
                state.attachRbacRoles.loading = false;
                state.attachRbacRoles.error = action.error.message || 'Someting wrong occured'
                state.attachRbacRoles.done = false
            })
    }
})

export {
    findOneAction,
    findAllAction,
    createAction,
    deleteAction,
    updateInfoAction,
    updatePasswordAction,
    attachRbacRolesAction
}

export const {
    resetDataTableAction,
    resetCreateAction,
    resetDeleteAction,
    resetUpdateInfoAction,
    resetUpdatePasswordAction,
    resetAttachRbacRolesAction
} = slice.actions

export const selector = (state: RootState) => state.userEmployee
export default slice.reducer;
