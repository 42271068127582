import { useState } from "react";
import DeleteOptionDialog from "./DeleteOptionDialog";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    rowIndex: number
    onDelete: (index: number) => void
}

export default function DeleteActionColumn(props: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCancelDeletion = () => {
        setAnchorEl(null);
    }

    const handleConfirmDeletion = () => {
        setAnchorEl(null);
        props.onDelete(props.rowIndex)
    }

    return (
        <>
            <IconButton aria-label="Eliminar" onClick={handleDeleteButtonClick}>
                <DeleteIcon />
            </IconButton>

            <Popover
                id={`delete-${props.rowIndex}-popover`}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCancelDeletion}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DeleteOptionDialog
                    onConfirm={handleConfirmDeletion}
                    onCancelled={handleCancelDeletion}
                />
            </Popover>
        </>
    )
}