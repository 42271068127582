import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const sliceName = "dashboard";
const initialState = {}

export const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => { },
});

export { };
export const { } = slice.actions;
export const selector = (state: RootState) => state.role;
export default slice.reducer;
