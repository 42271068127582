import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { themeOptions } from './app/themes/sapiolab'

import { store } from './app/redux/store';
import Home from './app/pages/Home';
import PageNotFound from './app/pages/PageNotFound';

import AuthLayout from './app/containers/Layout/AuthLayout';
import PrivateLayout from './app/containers/Layout/PrivateLayout';

import Login from './app/pages/Auth/Login';
import RequestPasswordReset from './app/pages/Auth/RequestPasswordReset';
import ResetPassword from './app/pages/Auth/ResetPassword';

import Organization from './app/modules/organization/pages';
import Employee from './app/modules/user-employee/pages';
import UserBackOffice from './app/modules/user-backoffice/pages';
import OrganizationalUnit from 'app/modules/organizational-unit/pages';
import Role from 'app/modules/role/pages';
import Policy from 'app/modules/policy/pages';
import Statement from 'app/modules/statement/pages';
import Profile from 'app/modules/profile/pages';
import Dashboard from 'app/modules/dashboard/pages';
import Test from 'app/modules/test/pages';
import TestVersion from 'app/modules/test-version/pages';
import Asessment from 'app/modules/assessment/pages';
import TestDimension from 'app/modules/test-dimension/pages';
import QuestionType from 'app/modules/question-type/pages';
import TestQuestion from 'app/modules/test-question/pages';
import Plan from 'app/modules/plan/pages';
import Contract from 'app/modules/contract/pages';
import TestApplicationRecord from 'app/modules/test-application-record/pages';

const theme = createTheme(themeOptions);
// const persistor = persistStore(store);

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'Backoffice'
  })

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <PersistGate persistor={persistor}> */}
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Router>
              <Routes>
                <Route path='/' element={<Home />} />

                <Route path='auth' element={<AuthLayout />}>
                  <Route path='' element={<Navigate to={'login'} />} />
                  <Route path='login' element={<Login />} />
                  <Route path='request-password-reset' element={<RequestPasswordReset />} />
                  <Route path='reset-password' element={<ResetPassword />} />
                </Route>

                <Route path='backoffice' element={<PrivateLayout />}>
                  <Route path='' element={<Navigate to={'dashboard'} />} />
                  <Route path='dashboard/*' element={<Dashboard />} />
                  <Route path='organization/*' element={<Organization />} />
                  <Route path='organizational-unit/*' element={<OrganizationalUnit />} />
                  <Route path='user-employee/*' element={<Employee />} />
                  <Route path='user-backoffice/*' element={<UserBackOffice />} />
                  <Route path='role/*' element={<Role />} />
                  <Route path='policy/*' element={<Policy />} />
                  <Route path='statement/*' element={<Statement />} />
                  <Route path='profile/*' element={<Profile />} />
                  <Route path='test/*' element={<Test />} />
                  <Route path='test-version/*' element={<TestVersion />} />
                  <Route path='test-dimension/*' element={<TestDimension />} />
                  <Route path='question-type/*' element={<QuestionType />} />
                  <Route path='test-question/*' element={<TestQuestion />} />
                  <Route path='assessment/*' element={<Asessment />} />
                  <Route path='plan/*' element={<Plan />} />
                  <Route path='contract/*' element={<Contract />} />
                  <Route path='test-application-record/*' element={<TestApplicationRecord />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Router>
            </LocalizationProvider>
          </ThemeProvider>
        </ReduxProvider>
      {/* </PersistGate> */}
    </React.Fragment>
  )
}

export default App
