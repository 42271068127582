import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./role.entity";
import { RoleService } from "./role.service";
import { RootState } from "../../redux/store";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { UpdateInfo } from './pages/Update/update.dto'
import { AttachPoliciesDto } from "./dto/attach-policies.dto";
import { FindOneDto } from "./dto/find-one.dto";
import { initialActionState, initialListState, initialViewState, initialCreateState, initialUpdateState, initialDeleteState } from "app/redux/helpers/state.helper";

const sliceName = "role";

export const initialState = {
  create: initialCreateState(),
  list: initialListState<EntitySchema>(),
  view: initialViewState<EntitySchema>(),
  update: initialUpdateState(),
  delete: initialDeleteState(),
  attachPolicies: initialActionState
}

const findOneAction = createAsyncThunk(
  `${sliceName}/findOne`,
  async (input: FindOneDto) => {
    const service = new RoleService()

    return service.findOne(input)
  }
)

const findAllAction = createAsyncThunk(
  `${sliceName}/findAll`,
  async (input: ListDto) => {
    const service = new RoleService();

    return service.findAll(input);
  }
);

const createAction = createAsyncThunk(
  `${sliceName}/create`,
  (input: CreateDto) => {
    const service = new RoleService()

    return service.create(input)
  }
)

const updateAction = createAsyncThunk(
  `${sliceName}/update`,
  async (input: UpdateInfo) => {
    const { id, dto } = input
    const service = new RoleService()

    return service.update(id, dto)
  }
)

const deleteAction = createAsyncThunk(
  `${sliceName}/delete`,
  (input: DeleteDto) => {
    const service = new RoleService()

    return service.delete(input)
  }
)

const attachPoliciesAction = createAsyncThunk(
  `${sliceName}/attachPolicies`,
  (input: AttachPoliciesDto) => {
    const service = new RoleService()

    return service.attachPolicies(input)
  }
)

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetListAction: (state) => {
      state.list = initialState.list
    },
    resetCreateAction: (state) => {
      state.create = initialState.create
    },
    resetViewAction: (state) => {
      state.view = initialState.view
    },
    resetUpdateAction: (state) => {
      state.update = initialState.update
    },
    resetDeleteAction: (state) => {
      state.delete = initialState.delete
    },
    resetAttachPoliciesAction: (state) => {
      state.attachPolicies = initialState.attachPolicies
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllAction.pending, (state) => {
        state.list.loading = true;
        state.list.done = false
        state.list.error = initialState.list.error
        state.list.data = initialState.list.data
        state.list.meta = initialState.list.meta
      })
      .addCase(findAllAction.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.done = true
        state.list.error = initialState.list.error
        state.list.data = action.payload.data
        state.list.meta = action.payload.meta
      })
      .addCase(findAllAction.rejected, (state, action) => {
        state.list.loading = false;
        state.list.done = false
        state.list.error = action.error.message || 'Someting wrong occured'
        state.list.data = initialState.list.data
        state.list.meta = initialState.list.meta
      })

      .addCase(createAction.pending, (state) => {
        state.create.loading = true;
        state.create.done = false
        state.create.error = initialState.create.error
        state.create.id = initialState.create.id
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.create.loading = false;
        state.create.done = true
        state.create.error = initialState.create.error
        state.create.id = action.payload
      })
      .addCase(createAction.rejected, (state, action) => {
        state.create.loading = false;
        state.create.done = false
        state.create.error = action.error.message || 'Someting wrong occured'
        state.create.id = initialState.create.id
      })

      .addCase(findOneAction.pending, (state) => {
        state.view.loading = true;
        state.view.done = false
        state.view.error = initialState.view.error
        state.view.data = initialState.view.data
      })
      .addCase(findOneAction.fulfilled, (state, action) => {
        state.view.loading = false;
        state.view.done = true
        state.view.error = initialState.view.error
        state.view.data = action.payload
      })
      .addCase(findOneAction.rejected, (state, action) => {
        state.view.loading = false;
        state.view.done = true
        state.view.error = action.error.message || 'Someting wrong occured'
        state.view.data = initialState.view.data
      })

      .addCase(updateAction.pending, (state) => {
        state.update.loading = true;
        state.update.done = false
        state.update.error = initialState.update.error
      })
      .addCase(updateAction.fulfilled, (state, action) => {
        state.update.loading = false;
        state.update.done = true
        state.update.error = initialState.update.error
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.update.loading = false;
        state.update.done = false
        state.update.error = action.error.message || 'Someting wrong occured'
      })

      .addCase(deleteAction.pending, (state) => {
        state.delete.loading = true;
        state.delete.done = false
        state.delete.error = initialState.delete.error
      })
      .addCase(deleteAction.fulfilled, (state, action) => {
        state.delete.loading = false;
        state.delete.done = true
        state.delete.error = initialState.delete.error
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.delete.loading = false;
        state.delete.done = false
        state.delete.error = action.payload as string
      })

      .addCase(attachPoliciesAction.pending, (state) => {
        state.attachPolicies.loading = true;
        state.attachPolicies.error = ''
        state.attachPolicies.done = false
      })
      .addCase(attachPoliciesAction.fulfilled, (state, action) => {
        state.attachPolicies.loading = false;
        state.attachPolicies.error = ''
        state.attachPolicies.done = action.payload
      })
      .addCase(attachPoliciesAction.rejected, (state, action) => {
        state.attachPolicies.loading = false;
        state.attachPolicies.error = action.error.message || 'Someting wrong occured'
        state.attachPolicies.done = false
      })
  },
});

export { findOneAction, findAllAction, createAction, deleteAction, updateAction, attachPoliciesAction };

export const { resetListAction, resetCreateAction, resetDeleteAction, resetUpdateAction, resetAttachPoliciesAction } = slice.actions;
export const selector = (state: RootState) => state.role;
export default slice.reducer;
