import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { mapValidationErrors, validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updateAction, resetUpdateAction, findOneAction } from '../../test-question.slice'
import { UpdateDto } from './update.dto'
import { Entity } from "../../test-question.entity";
import { SelectChangeEvent } from "@mui/material/Select";
import WysiwygInput from "app/components/WysiwygInput";
import TestDimensionSelectInput from "app/modules/test-dimension/components/TestDimensionSelectInput";
import { testDimensionFeature } from "app/modules/test-dimension/test-dimension.feature";
import QuestionTypeSelectInput from "app/modules/question-type/components/QuestionTypeSelectInput";
import { questionTypeFeature } from "app/modules/question-type/question-type.feature";
import SelectInput, { SelectInputOption } from "app/components/SelectInput";
import AnswerInput, { Answer } from "../../components/AnswerInput";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const initialState: UpdateDto = {
    code: '',
    statement: '',
    questionTypeId: '',
    testDimensionId: '',
    answersOrder: '',
    answerJson: []
}

const initialErrorState = {
    code: '',
    statement: '',
    questionTypeId: '',
    testDimensionId: '',
    answersOrder: '',
    answerJson: ''
}

const answersOrderOptions: SelectInputOption[] = [
    {
        label: 'Estático',
        value: 'STATIC'
    },
    {
        label: 'Aleatorio',
        value: 'RANDOM'
    }
]

const uiEntity = new Entity()

export default function Update(props: Props) {
    const dispatch = useAppDispatch()
    const viewSelector = useAppSelector(state => state.testQuestion.view)
    const updateSelector = useAppSelector(state => state.testQuestion.update)

    const [form, setForm] = useState<UpdateDto>(initialState)
    const [formError, setFormError] = useState(initialErrorState)

    const [questionClass, setQuestionClass] = useState('')

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id
        }))
    }, [])

    useEffect(() => {
        if (!viewSelector.data) return

        setForm({
            code: viewSelector.data.code,
            statement: viewSelector.data.statement,
            testDimensionId: viewSelector.data.testDimensionId,
            questionTypeId: viewSelector.data.questionTypeId,
            answersOrder: viewSelector.data.answersOrder,
            answerJson: viewSelector.data.answerJson
        })
        setQuestionClass(viewSelector.data.questionType.class)
    }, [viewSelector.data])

    useEffect(() => {
        if (updateSelector.done && !!!updateSelector.error) {
            dispatch(resetUpdateAction())
            props.onCompleted(updateSelector.done)
        }
    }, [updateSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleQuestionTypeSelectInputChange = (name: string, id: string, questionClass: string) => {
        setForm({
            ...form,
            [name]: id
        })

        setQuestionClass(questionClass)
    }

    const handleWysiwygChange = (name: string, value: string) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleAnswerInputChange = (answers: Answer[]) => {
        setForm({
            ...form,
            answerJson: answers,
        })
    }

    const handleFormSubmit = () => {
        setFormError(initialErrorState)
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setFormError({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(updateAction({
                id: viewSelector.data?.id!,
                dto: form
            }))
        }
    }

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="code"
                        label="Código"
                        value={form.code}
                        onChange={handleInputChange}
                        error={Boolean(formError.code)}
                        helperText={formError.code}
                    />

                    <WysiwygInput
                        fullWidth
                        name="statement"
                        label="Enunciado"
                        value={form.statement}
                        error={formError.statement}
                        onChange={handleWysiwygChange}
                    />

                    <TestDimensionSelectInput
                        name="testDimensionId"
                        label={testDimensionFeature.label.singular}
                        value={form.testDimensionId}
                        error={formError.testDimensionId}
                        onChange={handleSelectChange}
                    />

                    <QuestionTypeSelectInput
                        name="questionTypeId"
                        label={questionTypeFeature.label.singular}
                        value={form.questionTypeId}
                        error={formError.questionTypeId}
                        onChange={handleQuestionTypeSelectInputChange}
                    />

                    <SelectInput
                        fullWidth
                        name="answersOrder"
                        label="Ordenamiento de las preguntas"
                        value={form.answersOrder}
                        options={answersOrderOptions}
                        error={formError.answersOrder}
                        onChange={handleSelectChange}
                    />

                    <AnswerInput
                        questionClass={questionClass}
                        name="answerJson"
                        label="Respuesta"
                        value={form.answerJson}
                        error={formError.answerJson}
                        onChange={handleAnswerInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateSelector.loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
