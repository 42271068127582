import SecurityIcon from '@mui/icons-material/Security';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Feature } from "../types/feature.type";
import { organizationFeature } from "../modules/organization/organization.feature";
import { userEmployeeFeature } from "../modules/user-employee/user-employee.feature";
import { organizationalUnitFeature } from "../modules/organizational-unit/organizational-unit.feature";
import { dashboardFeature } from "app/modules/dashboard/dashboard.feature";
import { roleFeature } from 'app/modules/role/role.feature';
import { policyFeature } from 'app/modules/policy/policy.feature';
import { statementFeature } from 'app/modules/statement/statement.feature';
import { feminineArticle } from './articles';
import { testFeature } from 'app/modules/test/test.feature';
import { testVersionFeature } from 'app/modules/test-version/test-version.feature';
import { AuthorizationService } from 'app/services/AuthorizationService';
import { userBackofficeFeature } from 'app/modules/user-backoffice/user-backoffice.feature';
import { testDimensionFeature } from 'app/modules/test-dimension/test-dimension.feature';
import { questionTypeFeature } from 'app/modules/question-type/question-type.feature';
import { testQuestionFeature } from 'app/modules/test-question/test-question.feature';
import { planFeature } from 'app/modules/plan/plan.feature';
import { contractFeature } from 'app/modules/contract/contract.feature';
import { testApplicationRecordFeature } from 'app/modules/test-application-record/test-application-record.feature';

export const features: Feature[] = [
    dashboardFeature,
    {
        label: {
            plural: 'Clientes',
            singular: 'Cliente',
        },
        menu: {
            grammaticalNumber: 'plural'
        },
        icon: ManageAccountsIcon,
        isAuthorized: (
            AuthorizationService.userIsAuthorized('organization:findAll') ||
            AuthorizationService.userIsAuthorized('organizational-unit:findAll') ||
            AuthorizationService.userIsAuthorized('user-employee:findAll')
        ),
        entryLink: '#',
        article: feminineArticle,
        features: [
            organizationFeature,
            organizationalUnitFeature,
            userEmployeeFeature,
            testApplicationRecordFeature
        ]
    },
    {
        label: {
            plural: 'Ventas',
            singular: 'Venta',
        },
        menu: {
            grammaticalNumber: 'plural'
        },
        icon: StorefrontIcon,
        isAuthorized: (
            AuthorizationService.userIsAuthorized('plan:findAll') ||
            AuthorizationService.userIsAuthorized('contract:findAll')
        ),
        entryLink: '#',
        article: feminineArticle,
        features: [
            planFeature,
            contractFeature
        ]
    },
    {
        label: {
            plural: 'Pruebas',
            singular: 'Prueba',
        },
        menu: {
            grammaticalNumber: 'plural'
        },
        icon: PsychologyIcon,
        isAuthorized: (
            AuthorizationService.userIsAuthorized('test:findAll') ||
            AuthorizationService.userIsAuthorized('test-version:findAll') ||
            AuthorizationService.userIsAuthorized('test-dimension:findAll') ||
            AuthorizationService.userIsAuthorized('question-type:findAll')
        ),
        entryLink: '#',
        article: feminineArticle,
        features: [
            testFeature,
            testVersionFeature,
            testDimensionFeature,
            questionTypeFeature,
            testQuestionFeature
        ]
    },
    {
        label: {
            plural: 'Seguridades',
            singular: 'Seguridad',
        },
        menu: {
            grammaticalNumber: 'singular'
        },
        icon: SecurityIcon,
        isAuthorized: (
            AuthorizationService.userIsAuthorized('user-backoffice:findAll') ||
            AuthorizationService.userIsAuthorized('rbac-role:findAll') ||
            AuthorizationService.userIsAuthorized('rbac-policy:findAll') ||
            AuthorizationService.userIsAuthorized('rbac-statement:findAll')
        ),
        entryLink: '#',
        article: feminineArticle,
        features: [
            userBackofficeFeature,
            roleFeature,
            policyFeature,
            statementFeature
        ]
    }
]