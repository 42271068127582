import Joi from "joi";
import { CreateDto } from "./create.dto";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";
import { testDimensionFeature } from "../../test-dimension.feature";

export const schema = Joi.object<CreateDto, true>({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    description: Joi
        .string()
        .label('Descripción')
        .allow('')
        .optional(),
    testVersionId: Joi
        .string()
        .label(testVersionFeature.label.singular)
        .uuid()
        .required(),
    testDimensionId: Joi
        .string()
        .label(testDimensionFeature.label.singular)
        .uuid()
        .allow('')
        .optional()
})
