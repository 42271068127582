import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { CreateDto } from "./pages/Create/create.dto";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./plan.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";
import { isAxiosError } from "axios";
import { AttachTestVersionsDto } from "./dto/attach-test-versions.dto";
import { FindOneDto } from "./dto/find-one.dto";

export class PlanService extends ProtectedService {
    async findOne(dto: FindOneDto): Promise<EntitySchema> {
        const response = await this.axios.get(`/plan/${dto.id}`, {
            params: {
                attributes: dto.attributes,
                join: dto.join
            }
        })

        return response.data
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/plan', {
            params: dto
        })

        return response.data
    }

    async create(dto: CreateDto): Promise<string | undefined> {
        const response = await this.axios.post('/plan', dto)

        if (response.status === 201) {
            return response.data.id
        }

        return undefined
    }

    async update(id: string, dto: UpdateDto): Promise<boolean> {
        const response = await this.axios.patch(`/plan/${id}`, dto)

        return response.status === 200
    }

    async delete(dto: DeleteDto) {
        let ok = false
        let message = ''

        try {
            const result = await this.axios.delete(`/plan/${dto.id}`)

            ok = true
            message = result.data
        } catch (error) {
            if (isAxiosError(error)) {
                ok = false
                message = error.response?.data.error
            } else {
                ok = false
                message = 'Error desconocido'
            }
        }

        return {
            ok,
            message
        }
    }

    async attachTestVersions(dto: AttachTestVersionsDto) {
        const response = await this.axios.patch(`/plan/${dto.id}/test-versions`, {
            testVersionIds: dto.testVersionIds
        })

        return response.status === 200
    }
}
