import Joi from "joi";
import { CreateDto } from "./create.dto";
import { questionTypeFeature } from "app/modules/question-type/question-type.feature";
import { testDimensionFeature } from "app/modules/test-dimension/test-dimension.feature";

const answerJsonSchema = {
    id: Joi.string().uuid().required(),
    value: Joi.string().required(),
    expected: Joi.string().allow('').optional(),
    weight: Joi.number().required(),
}

export const schema = Joi.object<CreateDto, true>({
    code: Joi
        .string()
        .label('Código')
        .min(3)
        .max(255)
        .required(),
    statement: Joi
        .string()
        .label('Enunciado')
        .required(),
    questionTypeId: Joi
        .string()
        .label(questionTypeFeature.label.singular)
        .required(),
    testDimensionId: Joi
        .string()
        .label(testDimensionFeature.label.singular)
        .required(),
    answerJson: Joi
        .array()
        .items(answerJsonSchema)
        .required(),
    answersOrder: Joi
        .string()
        .label('Ordenamiento de las preguntas')
        .required(),
})
