import StarterKit from "@tiptap/starter-kit";
import { Underline } from "@tiptap/extension-underline";
import {
    MenuButtonBlockquote,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonUnderline,
    MenuControlsContainer,
    MenuDivider,
    RichTextEditor,
    RichTextEditorRef
} from "mui-tiptap";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect, useRef } from "react";

type Props = {
    fullWidth?: boolean
    name: string
    label: string
    error: string
    value?: string
    onChange?: (name: string, text: string) => void
}

const extensions = [
    StarterKit,
    Underline
];

export default function WysiwygInput(props: Props) {
    const rteRef = useRef<RichTextEditorRef>(null);

    const handleUpdate = (html: string) => {
        if (props.onChange) {
            props.onChange(props.name, html)
        }
    }

    useEffect(() => {
        if(rteRef) {
            rteRef.current?.editor?.commands.setContent(props.value || 'NA')
        }
    }, [props.value])

    return (
        <FormControl
            fullWidth={props.fullWidth}
            margin="normal"
            error={Boolean(props.error)}
            sx={{
                '.form-control-text-area': {
                    minHeight: '20vh'
                }
            }}
        >

            <FormHelperText variant="standard">
                {props.label}
            </FormHelperText>

            <RichTextEditor
                ref={rteRef}
                extensions={extensions}
                content={props.value}
                RichTextFieldProps={{
                    variant: 'outlined'
                }}
                editorProps={{
                    attributes: {
                        class: 'form-control-text-area'
                    }
                }}
                onUpdate={(props) => { handleUpdate(props.editor.getHTML()) }}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuButtonBold />
                        <MenuButtonItalic />
                        <MenuButtonStrikethrough />
                        <MenuButtonUnderline />
                        <MenuDivider />
                        <MenuButtonOrderedList />
                        <MenuButtonBulletedList />
                        <MenuDivider />
                        <MenuButtonBlockquote />
                        <MenuDivider />
                        <MenuButtonRemoveFormatting />
                    </MenuControlsContainer>
                )}
            />

            {
                Boolean(props.error) &&
                (
                    <FormHelperText error>
                        {props.error}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}