import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import { MRT_ColumnFiltersState, MRT_SortingState, MRT_PaginationState } from "material-react-table";
import { FilterState, SortingState } from 'app/types/pagination.dto';
import { useEffect, useState } from "react";
import ActionButtons, { ActionButton } from "../../../../components/ActionButtons";
import DataTable from "../../../../components/DataTable";
import FormDialog from "../../../../components/FormDialog";
import PageHeader from "../../../../components/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { AuthorizationService } from "../../../../services/AuthorizationService";
import { Entity, EntitySchema } from "../../policy.entity";
import { policyFeature } from "../../policy.feature";
import { initialState, findAllAction } from "../../policy.slice";
import Create from "../Create";
import Delete from "../Delete";
import Update from "../Update";
import { buildFilterByQueryParams } from 'app/helpers/pagination.helper';

const feature = policyFeature;
const entity = new Entity();

export default function List() {
    const dispatch = useAppDispatch();
    const dataTable = useAppSelector((state) => state.policy.list);
    const deleteSelector = useAppSelector(state => state.policy.delete);
    const filterInitialState: FilterState | undefined = buildFilterByQueryParams([])

    const [selectedRow, setSelectedRow] = useState<EntitySchema | null>(null)
    const [sorting, setSorting] = useState<SortingState>()
    const [filter, setFilter] = useState<FilterState | undefined>(filterInitialState)
    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: initialState.list.meta.page,
        pageSize: initialState.list.meta.perPage
    })

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [refreshEnabled, setRefreshEnabled] = useState(false);
    const [createEnabled, setCreateEnabled] = useState(false);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [deleteEnabled, setDeleteEnabled] = useState(false);

    const [refreshVisible, setRefreshVisible] = useState(false);
    const [createVisible, setCreateVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);

    const refreshData = () => {
        dispatch(findAllAction({
            page: page.pageIndex,
            perPage: page.pageSize,
            sortBy: sorting?.query,
            filter: filter?.query,
            join: entity.getJoinQuery(),
            joinAndCount: entity.getJoinAndCountQuery()
        }))
    }

    useEffect(() => {
        const userIsAuthorizedToFindAll = AuthorizationService.userIsAuthorized("rbac-policy:findAll");

        setRefreshVisible(userIsAuthorizedToFindAll);
        setRefreshEnabled(userIsAuthorizedToFindAll);

        setCreateVisible(AuthorizationService.userIsAuthorized("rbac-policy:create"));
        setUpdateVisible(AuthorizationService.userIsAuthorized("rbac-policy:update"));
        setDeleteVisible(AuthorizationService.userIsAuthorized("rbac-policy:delete"));
    }, []);

    useEffect(() => {
        if(deleteSelector.done) setSelectedRow(null)
    }, [deleteSelector.done]);

    useEffect(() => {
        setCreateEnabled(!selectedRow);
        setUpdateEnabled(!!selectedRow);
        setDeleteEnabled(!!selectedRow);
    }, [selectedRow]);

    useEffect(() => {
        refreshData()
    }, [sorting, filter, page])

    const handleRowSelected = (row: EntitySchema | null) => {
        setSelectedRow(row)
    }

    const handleSortingChange = (sorting: MRT_SortingState, query?: string) => {
        setSorting({
            state: sorting,
            query
        })
    }

    const handleFilterChange = (filter: MRT_ColumnFiltersState, query?: string) => {
        setFilter({
            state: filter,
            query
        })
    }

    const handlePageChange = (page: MRT_PaginationState) => {
        setPage({
            pageIndex: page.pageIndex + 1,
            pageSize: page.pageSize
        })
    }

    const handleCreateCancelled = () => {
        setCreateModalOpen(false)
    }

    const handleCreateCompleted = (created: boolean) => {
        if (created) {
            setCreateModalOpen(false)
            refreshData()
        }
    }

    const handleUpdateCancelled = () => {
        setUpdateModalOpen(false)
    }

    const handleUpdateCompleted = (updated: boolean) => {
        if (updated) {
            setUpdateModalOpen(false)
            refreshData()
        }
    }

    const handleDeleteCancelled = () => {
        setDeleteModalOpen(false)
    }

    const handleDeleteCompleted = (deleted: boolean) => {
        if (deleted) {
            setDeleteModalOpen(false)
            refreshData()
        }
    }

    const leftButtons: ActionButton[] = [
        {
            label: 'Refrescar',
            icon: UpdateIcon,
            color: 'primary',
            isEnabled: refreshEnabled,
            isVisible: refreshVisible,
            onClick: () => refreshData()
        },
    ]

    const rightButtons: ActionButton[] = [
        {
            label: 'Crear',
            icon: AddIcon,
            color: 'primary',
            isEnabled: createEnabled,
            isVisible: createVisible,
            onClick: () => setCreateModalOpen(true)
        },
        {
            label: 'Actualizar',
            icon: EditIcon,
            color: 'primary',
            isEnabled: updateEnabled,
            isVisible: updateVisible,
            onClick: () => setUpdateModalOpen(true)
        },
        {
            label: 'Eliminar',
            icon: DeleteIcon,
            color: 'error',
            isEnabled: deleteEnabled,
            isVisible: deleteVisible,
            onClick: () => setDeleteModalOpen(true)
        }
    ]

    return (
        <>
            <PageHeader
                title={feature.label.plural}
                icon={feature.icon}
            />

            <ActionButtons
                leftButtons={leftButtons}
                rightButtons={rightButtons}
            />

            <DataTable
                entity={entity}
                dataTable={dataTable}
                sorting={sorting?.state}
                filter={filter?.state}

                onRowSelected={handleRowSelected}
                onSortingChange={handleSortingChange}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
            />

            <FormDialog open={createModalOpen}>
                <Create
                    onCancelled={handleCreateCancelled}
                    onCompleted={handleCreateCompleted}
                />
            </FormDialog>

            <FormDialog open={updateModalOpen}>
                <Update
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={handleUpdateCancelled}
                    onCompleted={handleUpdateCompleted}
                />
            </FormDialog>

            <FormDialog open={deleteModalOpen}>
                <Delete
                    id={selectedRow?.id ? selectedRow.id : ''}
                    onCancelled={handleDeleteCancelled}
                    onCompleted={handleDeleteCompleted}
                />
            </FormDialog>
        </>
    );
}
