import Joi from "joi";
import { UpdateDto } from "./update.dto";
import { userEmployeeFeature } from "app/modules/user-employee/user-employee.feature";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";

export const schema = Joi.object<UpdateDto, true>({
    expirationDate: Joi
        .string()
        .label('Fecha máxima para presentar la prueba')
        .required(),
    profileId: Joi
        .string()
        .label(userEmployeeFeature.label.singular)
        .required(),
    testVersionId: Joi
        .string()
        .label(testVersionFeature.label.singular)
        .required(),
})
