import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#151B28',
    },
    secondary: {
      main: '#03E28B',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Sans-serif',
    ].join(','),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  }
};
