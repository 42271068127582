import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./contract.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";
import { isAxiosError } from "axios";
import { CreateFromPlanDto } from "./pages/CreateFromPlan/create-from-plan.dto";

export class ContractService extends ProtectedService {
    async findOne(id: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/contract/${id}`)

        return response.data
    }

    async findOneByOrganization(organizationId: string): Promise<EntitySchema> {
        const response = await this.axios.get(`/contract/organization/${organizationId}`)

        return response.data
    }

    async findPlanByContract(idContract: string) {
        let ok = false
        let message = ''

        try {
            const result = await this.axios.get(`/contract/plan/${idContract}`)

            ok = true
            message = result.data
        } catch (error) {
            if (isAxiosError(error)) {
                ok = false
                message = error.response?.data.error
            } else {
                ok = false
                message = 'Error desconocido'
            }
        }

        return {
            ok,
            message
        }
    }

    async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
        const response = await this.axios.get('/contract', {
            params: dto
        })

        return response.data
    }

    async createFromPlan(dto: CreateFromPlanDto): Promise<string | undefined> {
        const response = await this.axios.post('/contract/from-plan', dto)

        if(response.status === 201) {
            return response.data.id
        }

        return undefined
    }

    async update(id: string, dto: UpdateDto): Promise<boolean> {
        const response = await this.axios.patch(`/contract/${id}`, dto)

        return response.status === 200
    }

    async delete(dto: DeleteDto) {
        let ok = false
        let message = ''

        try {
            const result = await this.axios.delete(`/contract/${dto.id}`)

            ok = true
            message = result.data
        } catch (error) {
            if (isAxiosError(error)) {
                ok = false
                message = error.response?.data.error
            } else {
                ok = false
                message = 'Error desconocido'
            }
        }

        return {
            ok,
            message
        }
    }
}
