import { SelectChangeEvent } from '@mui/material/Select';
import SelectInput from 'app/components/SelectInput';
import { PlanStatus } from '../../plan.entity';

type Props = {
    label: string
    name: string
    value?: string
    error?: string
    onChange?: (event: SelectChangeEvent) => void
}

type Option = {
    label: string
    value: string
}

const options: Option[] = [
    {
        label: 'Habilitado',
        value: PlanStatus.ENABLED
    },
    {
        label: 'Deshabilitado',
        value: PlanStatus.DISABLED
    }
]

export default function PlanStatusSelectInput(props: Props) {
    return (
        <SelectInput
            fullWidth
            name={props.name}
            label={props.label}
            value={props.value || ''}
            options={options}
            error={props.error || ''}
            onChange={props.onChange}
        />
    )
}