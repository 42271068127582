import { Card, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { v4 as uuidv4 } from 'uuid';
import DraggableRow from "./DraggableRow";
import { Answer } from "../AnswerInput";

type Props = {
    name: string
    value: Answer[]
    onChange: (answers: Answer[]) => void
}

const MultipleOptionOneAnswerInput = (props: Props) => {
    const [answers, setAnswers] = useState<Answer[]>(props.value)

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    useEffect(() => {
        props.onChange(answers)
    }, [answers])

    const handleAddAtBeginningClick = () => {
        setAnswers([
            {
                id: uuidv4(),
                value: '',
                expected: '',
                weight: 0
            },
            ...props.value
        ])
    }

    const handleAddAtEndClick = () => {
        setAnswers([
            ...props.value,
            {
                id: uuidv4(),
                value: '',
                expected: '',
                weight: 0
            }
        ])
    }

    const handleDeleteOption = (index: number) => {
        const values = [...props.value];

        values.splice(index, 1);

        setAnswers(values);
    }

    const handleLabelChange = (index: number, label: string) => {
        let values = [...props.value];

        if (values[index]) {
            values[index] = {
                ...values[index],
                value: label
            }

            setAnswers(values)
        }
    }

    const handleWeightChange = (index: number, weight: number) => {
        let values = [...props.value];

        if (values[index]) {
            values[index] = {
                ...values[index],
                weight: weight
            }

            setAnswers(values)
        }
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setAnswers((items) => {
                const oldIndex = items.findIndex(item => active.id === item.id);
                const newIndex = items.findIndex(item => over.id === item.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 1, p: 1 }}>
                    <Tooltip title="Agregar al inicio">
                        <IconButton onClick={handleAddAtBeginningClick}>
                            <AddIcon color="success" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <TableContainer component={Card}>
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Orden</TableCell>
                                <TableCell>Opción</TableCell>
                                <TableCell>¿Correcta?</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <SortableContext items={props.value} strategy={verticalListSortingStrategy}>
                                {
                                    props.value.map((answer, index) => {
                                        return (
                                            <DraggableRow
                                                key={answer.id}
                                                row={answer}
                                                rowIndex={index}
                                                onLabelChange={handleLabelChange}
                                                onWeightChange={handleWeightChange}
                                                onDelete={handleDeleteOption}
                                            />
                                        )
                                    })
                                }
                            </SortableContext>
                        </TableBody>
                    </Table>
                </DndContext>
            </TableContainer>

            <Grid container>
                <Grid item sx={{ mt: 1, p: 1 }}>
                    <Tooltip title="Agregar al final">
                        <IconButton onClick={handleAddAtEndClick}>
                            <AddIcon color="success" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}

export default MultipleOptionOneAnswerInput