import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createAction, resetCreateAction, attachTestVersionsAction, resetAttachTestVersionsAction } from '../../plan.slice'
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";
import { schema } from "./schema";
import { CreateDto } from './create.dto'
import { planFeature } from "../../plan.feature";
import { PlanStatus } from "../../plan.entity";
import PlanStatusSelectInput from "../../components/PlanStatusSelectInput";
import { SelectChangeEvent } from "@mui/material/Select";
import TestVersionCheckBoxGroupInput from "app/modules/test-version/components/TestVersionCheckBoxGroupInput";
import { testVersionFeature } from "app/modules/test-version/test-version.feature";

type Props = {
    onCancelled: () => void
    onCompleted: (id?: string) => void
}

const initialState: CreateDto = {
    name: '',
    status: `${PlanStatus.ENABLED}`,
    maxEmployeesNum: 0,
    durationInDays: 0,
    price: 0
}

const initialErrorState = {
    name: '',
    status: '',
    maxEmployeesNum: '',
    durationInDays: '',
    price: ''
}

const testVersionInitialState = {
    testVersionIds: []
}

const testVersionErrorsInitialState = {
    policyIds: ''
}

const feature = planFeature

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createSelector = useAppSelector(state => state.plan.create)
    const attachTestVersionsSelector = useAppSelector(state => state.plan.attachTestVersions)

    const [form, setForm] = useState<CreateDto>(initialState)
    const [errors, setErrors] = useState(initialErrorState)

    const [testVersionForm, setTestVersionForm] = useState(testVersionInitialState)
    const [testVersionFormErrors, setTestVersionFormErrors] = useState(testVersionErrorsInitialState)

    useEffect(() => {
        const sucessFullyCreated = (
            !createSelector.loading &&
            !!!createSelector.error &&
            createSelector.done &&
            Boolean(createSelector.id)
        )

        if (sucessFullyCreated) {
            dispatch(
                attachTestVersionsAction({
                    id: createSelector.id!,
                    testVersionIds: testVersionForm.testVersionIds
                })
            )
        }

    }, [createSelector.done])

    useEffect(() => {
        if (attachTestVersionsSelector.done && !!!attachTestVersionsSelector.error) {
            dispatch(resetCreateAction())
            dispatch(resetAttachTestVersionsAction())

            props.onCompleted(createSelector.id)
        }
    }, [attachTestVersionsSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleTestVersionInputChange = (name: string, value: string[]) => {
        setTestVersionForm({
            ...testVersionForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...initialErrorState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="durationInDays"
                        label="Duración en días"
                        value={form.durationInDays}
                        onChange={handleInputChange}
                        error={Boolean(errors.durationInDays)}
                        helperText={errors.durationInDays}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="maxEmployeesNum"
                        label="Número máximo de colaboradores"
                        value={form.maxEmployeesNum}
                        onChange={handleInputChange}
                        error={Boolean(errors.maxEmployeesNum)}
                        helperText={errors.maxEmployeesNum}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="price"
                        label="Valor en COP"
                        value={form.price}
                        onChange={handleInputChange}
                        error={Boolean(errors.price)}
                        helperText={errors.price}
                    />

                    <PlanStatusSelectInput
                        label="Estado del plan"
                        name="status"
                        value={form.status}
                        error={errors.status}
                        onChange={handleSelectChange}
                    />

                    <TestVersionCheckBoxGroupInput
                        label={testVersionFeature.label.plural}
                        name="testVersionIds"
                        value={testVersionForm.testVersionIds}
                        onChange={handleTestVersionInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
