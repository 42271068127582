import FormControl from "@mui/material/FormControl"
import MulitpleOptionMultipleAnswerInput from "../MulitpleOptionMultipleAnswerInput"
import MultipleOptionOneAnswerInput from "../MultipleOptionOneAnswerInput"
import MultipleOptionWithSortingInput from "../MultipleOptionWithSortingInput"
import FormHelperText from "@mui/material/FormHelperText"

export type Answer = {
    id: string
    value: string
    expected: string
    weight: number
}

type Props = {
    questionClass: string
    label: string
    name: string
    value: Answer[]
    error?: string
    onChange: (answers: Answer[]) => void
}

export default function AnswerInput(props: Props) {

    return (
        <FormControl
            fullWidth
            margin="normal"
            error={Boolean(props.error)}
        >

            <FormHelperText variant="standard">
                {props.label}
            </FormHelperText>

            {
                props.questionClass === 'MULTIPLE_OPTIONS_ONE_ANSWER' && (
                    <MultipleOptionOneAnswerInput
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                    />
                )
            }

            {
                props.questionClass === 'MULTIPLE_OPTIONS_MULTIPLE_ANSWER' && (
                    <MulitpleOptionMultipleAnswerInput
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                    />
                )
            }

            {
                props.questionClass === 'MULTIPLE_OPTIONS_SORTING' && (
                    <MultipleOptionWithSortingInput
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                    />
                )
            }

            {
                Boolean(props.error) &&
                (
                    <FormHelperText error>
                        {props.error}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}