import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateForm } from "../../../../helpers/validation";
import { schema } from "./schema";
import { updatePasswordAction, resetUpdatePasswordAction, findOneAction } from '../../user-backoffice.slice'
import { UpdatePasswordDto } from './update-password.dto'
import { Entity } from "../../user-backoffice.entity";
import AutoGeneratePasswordInput from "app/components/AutoGeneratePasswordInput";

type Props = {
    id: string
    onCancelled: () => void
    onCompleted: (done: boolean) => void
}

const formInitialState = {
    password: ''
}

const errorsInitialState = formInitialState
const uiEntity = new Entity()

export default function UpdatePassword(props: Props) {
    const dispatch = useAppDispatch()
    const updateSelector = useAppSelector(state => state.userEmployee.updatePassword)
    const [form, setForm] = useState<UpdatePasswordDto>(formInitialState)
    const [errors, setErrors] = useState<UpdatePasswordDto>(errorsInitialState)

    useEffect(() => {
        dispatch(findOneAction({
            id: props.id
        }))
    }, [])

    useEffect(() => {
        setForm(updateSelector.data)
    }, [updateSelector.data])

    useEffect(() => {
        if (updateSelector.done)
            props.onCompleted(updateSelector.done)

        dispatch(resetUpdatePasswordAction())
    }, [updateSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        const formData = {
            password: form.password,
        }

        const formValidation = validateForm(schema, formData)

        if (formValidation.error) {
            let validationErrors: Record<string, any> = {}

            for (const errorDetail of formValidation.error.details) {

                if (errorDetail.context?.key) {
                    validationErrors[errorDetail.context?.key] = errorDetail.message
                }
            }

            setErrors({
                ...errorsInitialState,
                ...validationErrors
            })
        } else {
            dispatch(updatePasswordAction({
                id: props.id,
                dto: formData
            }))
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Actualizar Contraseña de ` + uiEntity.name.singular}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <AutoGeneratePasswordInput
                        switchLabel="Generar contraseña"
                        switchEnabled={false}
                        inputLabel="Contraseña"
                        inputName="password"
                        inputValue={form.password}
                        inputError={errors.password}
                        onGenerate={(newPassword: string) => {
                            setForm({
                                ...form,
                                password: newPassword
                            })
                        }}
                        onChange={handleInputChange} />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={updateSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={updateSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Actualizar
                </Button>
            </CardActions>
        </Card>
    )
}
