import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled"
import { Answer } from "../AnswerInput"
import DeleteActionColumn from "../AnswerInput/DeleteActionColumn";

type Props = {
    rowIndex: number
    row: Answer
    onLabelChange: (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onWeightChange: (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onDelete: (index: number) => void
}

export default function DraggableRow(props: Props) {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: props.row.id
    });

    const DraggingRow = styled.td`
        background: rgba(127, 207, 250, 0.3);
    `;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition
    };

    return (
        <TableRow ref={setNodeRef} style={style}>
            {
                isDragging ?
                    (
                        <DraggingRow colSpan={4}>&nbsp;</DraggingRow>
                    ) :
                    (
                        <>
                            <TableCell width="5%">
                                <IconButton aria-label="delete" {...attributes} {...listeners}>
                                    <DragHandleIcon />
                                </IconButton>
                            </TableCell >

                            <TableCell width="80%">
                                <TextField
                                    fullWidth
                                    placeholder="Texto de la opción"
                                    variant="outlined"
                                    value={props.row.value}
                                    onChange={(event) => {
                                        props.onLabelChange(props.rowIndex, event)
                                    }}
                                />
                            </TableCell>
                            <TableCell width="10%">
                                <TextField
                                    fullWidth
                                    type="number"
                                    placeholder="Peso"
                                    variant="outlined"
                                    value={props.row.weight}
                                    onChange={(event) => {
                                        props.onWeightChange(props.rowIndex, event)
                                    }}
                                />
                            </TableCell>
                            <TableCell width="5%">
                                <DeleteActionColumn
                                    rowIndex={props.rowIndex}
                                    onDelete={props.onDelete}
                                />
                            </TableCell>
                        </>
                    )
            }
        </TableRow >
    )
}