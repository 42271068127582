import { CssVarsProvider, Snackbar } from "@mui/joy"
import { Typography, colors } from "@mui/material"
import { useState } from "react"

type Props = {
	textToCopy: string
	label: string
}

export default function CopyToClipboardButton(props: Props) {
	const [open, setOpen] = useState(false)
    const handleClick = () => {
		setOpen(true)
		navigator.clipboard.writeText(props.textToCopy)
    }
    
    return (
        <>
			<CssVarsProvider>
				<Typography 
					variant="body2"
					onClick={handleClick}
					sx={{
						textDecoration: 'underline'
					}}>
					{props.label}
				</Typography>
				<Snackbar
					color="primary"
					variant="solid"
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
					open={open}
					onClose={() => setOpen(false)}
					autoHideDuration={2000}
				>
					Elemento copiado
				</Snackbar>
			</CssVarsProvider>
        </>
    )
}