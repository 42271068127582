import Joi from "joi";
import { testDimensionFeature } from "../../test-dimension.feature";

export const schema = Joi.object({
    name: Joi
        .string()
        .label('Nombre')
        .min(3)
        .max(255)
        .required(),
    description: Joi
        .string()
        .label('Descripción')
        .allow('')
        .optional(),
    testDimensionId: Joi
        .string()
        .label(testDimensionFeature.label.singular)
        .uuid()
        .allow('')
        .optional()
})
