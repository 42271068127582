import { PaginatedResultInterface } from "../../interfaces/PaginatedResultInterface";
import { DeleteDto } from "./pages/Delete/delete.dto";
import { CreateDto } from "./pages/Create/create.dto";
import { ListDto } from "./pages/List/list.dto";
import { EntitySchema } from "./policy.entity";
import { ProtectedService } from "../../services/ProtectedService";
import { UpdateDto } from "./pages/Update/update.dto";
import { AttachStatementsDto } from "./dto/attach-statements.dto";
import { FindOneDto } from "./dto/find-one.dto";

export class PolicyService extends ProtectedService {

  async findOne(dto: FindOneDto): Promise<EntitySchema> {
    const response = await this.axios.get(`/policy/${dto.id}`, {
      params: {
        attributes: dto.attributes,
        join: dto.join
      }
    })

    return response.data
  }

  async findAll(dto: ListDto): Promise<PaginatedResultInterface<EntitySchema>> {
    const response = await this.axios.get("/policy", {
      params: dto,
    });

    return response.data;
  }

  async create(dto: CreateDto) {
    const response = await this.axios.post('/policy', dto)

    if (response.status === 201) {
      return response.data.id
    }

    return undefined
  }

  async update(id: string, dto: UpdateDto): Promise<boolean> {
    const response = await this.axios.patch(`/policy/${id}`, dto)

    return response.status === 200
  }

  async delete(dto: DeleteDto): Promise<boolean> {
    const response = await this.axios.delete(`/policy/${dto.id}`)

    return response.status === 200
  }

  async attachStatements(dto: AttachStatementsDto) {
    const response = await this.axios.patch(`/policy/${dto.id}/statements`, {
      statementIds: dto.statementIds
    })

    return response.status === 200
  }
}
