import { Navigate } from 'react-router-dom';
import { StorageService } from '../../../services/StorageService';
import ResponsiveAppBar from './ResponsiveAppBar';
import { features } from '../../../features';
import { buildMenuListItemsFromFeatures } from 'app/helpers/menu.helper';


export default function PrivateLayout() {
    const accessToken = StorageService.getAccessToken()
    const menuListItems = buildMenuListItemsFromFeatures(features)

    return !accessToken ?
        <Navigate to="/auth" /> :
        <ResponsiveAppBar menuListItems={menuListItems} />
}
