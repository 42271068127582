import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { validateForm, mapValidationErrors } from "../../../../helpers/validation";
import { schema } from "./schema";
import { createAction, attachRbacRolesAction, resetCreateAction, resetAttachRbacRolesAction } from '../../user-backoffice.slice'
import { CreateDto } from './create.dto'
import AutoGeneratePasswordInput from "../../../../components/AutoGeneratePasswordInput";
import { userBackofficeFeature } from "../../user-backoffice.feature";
import RolesCheckBoxGroupInput from "app/modules/role/components/RolesCheckBoxGroupInput";
import { RoleScopesEnum } from "app/modules/role/role.enums";

type Props = {
    onCancelled: () => void
    onCompleted: (created: boolean) => void
}

const initialState: CreateDto = {
    name: '',
    lastName: '',
    email: '',
    password: ''
}

const feature = userBackofficeFeature

const rbacRoleInitialState = {
    roleIds: []
}

const rbacRoleErrorsInitialState = {
    roleIds: ''
}

export default function Create(props: Props) {
    const dispatch = useAppDispatch()
    const createUserSelector = useAppSelector(state => state.userBackoffice.create)
    const attachRbacRolesSelector = useAppSelector(state => state.userBackoffice.attachRbacRoles)

    const [form, setForm] = useState<CreateDto>(initialState)
    const [errors, setErrors] = useState<CreateDto>(initialState)

    const [rbacRoleForm, setRbacRoleForm] = useState(rbacRoleInitialState)
    const [rbacRoleFormErrors, setRbacRoleFormErrors] = useState(rbacRoleErrorsInitialState)

    useEffect(() => {
        if (createUserSelector.done && !!!createUserSelector.error) {
            if (!createUserSelector.id) return

            dispatch(
                attachRbacRolesAction({
                    id: createUserSelector.id,
                    roleIds: rbacRoleForm.roleIds
                })
            )
        }
    }, [createUserSelector.done])

    useEffect(() => {
        if (attachRbacRolesSelector.done) {
            props.onCompleted(
                createUserSelector.done &&
                attachRbacRolesSelector.done
            )

            dispatch(resetCreateAction())
            dispatch(resetAttachRbacRolesAction())
        }
    }, [attachRbacRolesSelector.done])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleRoleInputChange = (name: string, value: string[]) => {
        setRbacRoleForm({
            ...rbacRoleForm,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const formValidation = validateForm(schema, form)

        if (formValidation.error) {
            setErrors({
                ...initialState,
                ...mapValidationErrors(formValidation.error)
            })
        } else {
            dispatch(
                createAction(form)
            )
        }
    };

    return (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader
                title={`Crear ${feature.label.singular}`}
                subheader="Complete el formulario a continuación"
            />
            <Divider />
            <CardContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="name"
                        label="Nombre"
                        value={form.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="text"
                        name="lastName"
                        label="Apellido"
                        value={form.lastName}
                        onChange={handleInputChange}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        value={form.email}
                        onChange={handleInputChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />

                    <AutoGeneratePasswordInput
                        switchLabel="Generar contraseña"
                        switchEnabled={true}
                        inputLabel="Contraseña"
                        inputName="password"
                        inputValue={form.password}
                        inputError={errors.password}
                        onGenerate={(newPassword: string) => {
                            setForm({
                                ...form,
                                password: newPassword
                            })
                        }}
                        onChange={handleInputChange} />

                    <RolesCheckBoxGroupInput
                        label="Roles"
                        name="roleIds"
                        scope={RoleScopesEnum.BACK_OFFICE}
                        value={rbacRoleForm.roleIds}
                        error={rbacRoleFormErrors.roleIds}
                        onChange={handleRoleInputChange}
                    />
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={createUserSelector.loading}
                    variant="outlined"
                    onClick={props.onCancelled}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={createUserSelector.loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Crear
                </Button>
            </CardActions>
        </Card>
    )
}
