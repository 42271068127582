import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import authenticatorReducer from "./authenticatorSlice";
import organizationReducer from "../modules/organization/organization.slice";
import organizationalUnitReducer from "../modules/organizational-unit/organizational-unit.slice";
import userEmployeeReducer from "../modules/user-employee/user-employee.slice";
import userBackofficeReducer from "../modules/user-backoffice/user-backoffice.slice";
import roleReducer from "../modules/role/role.slice";
import policyReducer from "../modules/policy/policy.slice";
import statementReducer from "../modules/statement/statement.slice";
import dashboardReducer from "../modules/dashboard/dashboard.slice";
import profileReducer from "../modules/profile/profile.slice";
import testReducer from "../modules/test/test.slice";
import testVersionReducer from "../modules/test-version/test-version.slice";
import testDimensionReducer from "../modules/test-dimension/test-dimension.slice";
import assessmentReducer from "../modules/assessment/assessment.slice";
import questionTypeReducer from "../modules/question-type/question-type.slice";
import testQuestionReducer from "../modules/test-question/test-question.slice";
import planReducer from "../modules/plan/plan.slice";
import contractReducer from "../modules/contract/contract.slice";
import testApplicationRecordReducer from "../modules/test-application-record/test-application-record.slice";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authenticator']
}

const rootReducer = combineReducers({
  authenticator: authenticatorReducer,
  dashboard: dashboardReducer,
  organization: organizationReducer,
  organizationalUnit: organizationalUnitReducer,
  userEmployee: userEmployeeReducer,
  userBackoffice: userBackofficeReducer,
  role: roleReducer,
  policy: policyReducer,
  statement: statementReducer,
  profile: profileReducer,
  assessment: assessmentReducer,
  test: testReducer,
  testVersion: testVersionReducer,
  testDimension: testDimensionReducer,
  questionType: questionTypeReducer,
  testQuestion: testQuestionReducer,
  plan: planReducer,
  contract: contractReducer,
  testApplicationRecord: testApplicationRecordReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
