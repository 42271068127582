import { Route, Routes } from "react-router-dom";
import List from "./List";

export default function Plan() {
    return (
        <>
            <Routes>
                <Route path={''} element={<List />} />
                <Route path={':id'} />
                <Route path={'update'} />
            </Routes>
        </>
    );
}
